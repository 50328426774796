import { GET_INVITERS, GET_ALL_INVITERS } from 'Pages/Statistics/Inviters/actions/actionTypes';


const initialState = {
    inviters: {},
    allInviters: []
}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_INVITERS:
            return {
                ...state,
                inviters: action.data
            }
            case GET_ALL_INVITERS: {
                return {
                    ...state,
                    allInviters: action.data
                }
            }
        default:
            return state;
    }
}