import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomModal from "UI/CustomModal/CustomModal"
import ReportIcon from "@material-ui/icons/Report";


class ConfirmModal extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <CustomModal open={this.props.open} close={this.props.close}>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="danger" className={classes.cardHeader}>
                            <ReportIcon />
                            <h4 className={classes.cardTitle}>Потвърждаване</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                {this.props.message}
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button
                                onClick={this.props.yesButtonClisk}
                                color="success">
                                Да
                            </Button>
                            <Button
                                onClick={this.props.close}
                                color="danger">
                                Не
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </CustomModal>
        );
    };

}

const styles = {

    cardTitle: {
        color: "white",
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

};




export default withStyles(styles)(ConfirmModal);
