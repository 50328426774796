export const GET_ALL_GAME_STATS_TYPES = "GET_ALL_GAME_STATS_TYPES";
export const GET_STATS_FOR_GAME = "GET_STATS_FOR_GAME";
export const ADD_STAT = "ADD_STAT";
export const DELETE_STAT = "DELETE_STAT";
export const START_FIRST_HALF = "START_FIRST_HALF";
export const END_FIRST_HALF = "END_FIRST_HALF";
export const START_SECOND_HALF = "START_SECOND_HALF";
export const END_SECOND_HALF = "END_SECOND_HALF";
export const SAVE_GAME_LIVE = "SAVE_GAME_LIVE";
export const GET_GAME_SCORE = "GET_GAME_SCORE";

