import React from 'react';
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import SnackbarContent from "vendor/components/Snackbar/SnackbarContent.jsx";
import Clearfix from "vendor/components/Clearfix/Clearfix.jsx";

const successModal = (props) => (

    props.show ?
        <div >
            <SnackbarContent
                message={
                    <span>
                        {props.text}
                    </span>
                }
                close
                color="success"
                closeNotification={() => props.closeClick()}
                icon={Check}
            />
            <Clearfix />
        </div> : null
);


export default successModal;