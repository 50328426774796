import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllFilteredUsers = (data) => {
    return dispatch => {

        axios.post('/stats/users', data)
            .then(res => {
                dispatch(setAllFilteredUsers(res.data.data));
            });
    };
};

export const setAllFilteredUsers = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_ALL_USERS,
        data: data
    };
};


export const editUser = (data) => {
    return dispatch => {

        axios.put('/users', data)
            .then(res => {
                dispatch(setEditedUsed(res.data.data));
            });
    };
};



export const setEditedUsed = (data) => {
    return {
        type: actionTypes.EDIT_USER,
        data: data
    };
};