import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import { getPopularCaptains, clearFiltersPopularCaptains } from 'Pages/Statistics/PopularCaptains/actions/popularCaptains.js';
import { getAllChampionships } from 'Pages/Championships/actions/championships.js';
import { getAllRounds } from 'Pages/Rounds/actions/rounds.js';
import { connect } from 'react-redux';
import Badge from "vendor/components/Badge/Badge.jsx"
import { getBadgeColorForGameStatus, darkBlueColor, getBadgeColorForRoundStatus, convertDate } from 'utils/Constants'
import moment from 'moment'
import Edit from "@material-ui/icons/Edit";
import CustomModal from "UI/CustomModal/CustomModal"
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import CustomSelect from "UI/CustomSelect/CustomSelect";
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import Email from "@material-ui/icons/Email";
import PersonOutline from "@material-ui/icons/PersonOutline";
import Location from "@material-ui/icons/LocationOn";
import Pagination from "UI/Pagination/Pagination";
import Button from "vendor/components/CustomButtons/Button.jsx";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class PopularCaptainsList extends React.Component {

    state = {
        lastPage: 1,
        page: 1,
        championship: {

        },
        round: {

        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.popularCaptains !== this.props.popularCaptains) {
            this.setState({
                page: nextProps.popularCaptains.current_page,
                lastPage: nextProps.popularCaptains.last_page,
            })
        }
    }

    componentWillMount() {
        this.props.getAllChampionships();
    }

    onChangePage = (page) => {

        let data = { ...this.state };
        data.page = page;
        this.props.getPopularCaptains(data);
    }


    handleSelect = (id, name) => {
        var selected;
        if (name === "championship") {
            selected = this.props.championships.find((t) => {
                return t.id === id;
            });
            this.props.getAllRounds(id);
        } else {
            selected = this.props.rounds.find((t) => {
                return t.id === id;
            });
        }

        this.setState({
            ...this.state,
            [name]: selected

        });
    }

    filterHandler = () => {
        let data = {
            ...this.state,
            page: 1,
        };

        this.props.getPopularCaptains(data);
    }

    clearFilters = () => {
        let data = {
            lastPage: 1,
            page: 1,
            championship: {

            },
            round: {

            }
        };

        this.setState({
            ...data

        });

        this.props.clearFiltersPopularCaptains();
        //this.props.getPopularCaptains(data);

    }

    render() {
        const { classes } = this.props;

        let mappedChamps = [];
        if (this.props.championships.length > 0) {
            mappedChamps = this.props.championships.map(t => {
                return {
                    valueData: [
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.logo} />
                            {t.name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }


        let mappedRounds = [];
        if (this.props.rounds.length > 0) {
            mappedRounds = this.props.rounds.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{t.name}</span>
                            <Badge color={getBadgeColorForRoundStatus(t.status.id)} >{t.status.name}</Badge>
                        </div>
                    ],
                    id: t.id
                };
            });
        }


        let mappedUsers = [];

        if (this.props.popularCaptains && this.props.popularCaptains && this.props.popularCaptains.length > 0) {
            mappedUsers = this.props.popularCaptains.map(t => {

                return {
                    tableData: [t.number, t.first_name, t.last_name, t.team_name, t.position, t.chosen_captain],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Най-избирани капитани:  {this.props.popularCaptains.total ? this.props.popularCaptains.total : " "}</h4>

                            </CardHeader>
                            <CardBody>
                                <GridContainer>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedSelect
                                            labelName="Шампионат"
                                            selectData={mappedChamps}
                                            value={this.state.championship.id}
                                            onChange={(event) => this.handleSelect(event, "championship")}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedSelect
                                            labelName="Кръг"
                                            selectData={mappedRounds}
                                            value={this.state.round.id}
                                            onChange={(event) => this.handleSelect(event, "round")}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.filterHandler}
                                            color="success">
                                            Филтрирай
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.clearFilters}
                                            color="danger">
                                            Изчисти филтрите
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Номер", "Първо Име", "Фамилия", "Отбор", "Позиция", "Избран от"]}
                                    tableData={mappedUsers}

                                />
                            </CardBody>
                            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.onChangePage}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        popularCaptains: state.statisticsPopularCaptains.popularCaptains,
        championships: state.championships.championships,
        rounds: state.rounds.rounds,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPopularCaptains: (data) => dispatch(getPopularCaptains(data)),
        getAllChampionships: () => dispatch(getAllChampionships()),
        getAllRounds: (id) => dispatch(getAllRounds(id)),
        clearFiltersPopularCaptains: () => dispatch(clearFiltersPopularCaptains())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PopularCaptainsList));
