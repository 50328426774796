import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import { Scrollbars } from 'react-custom-scrollbars';
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import { darkBlueColor } from "utils/Constants"
import { editSubGame } from "Pages/ChampionshipGames/actions/championshipGames";
import CustomCheckboxLabel from "UI/CustomCheckboxLabel/CustomCheckboxLabel"
import { connect } from 'react-redux';


class EditSubGameForm extends React.Component {

    state = {
        no_limit: false,
        manual_bet: false,
        unlimited_entries: false,
    }

    componentDidMount() {
        this.setState(
            {
             ...this.state,
              ...this.props.subGame, 
              no_limit: this.props.subGame.max_players == -1,
              unlimited_entries: this.props.subGame.max_entries_from_single_users == -1,
              manual_bet: this.props.subGame.manual_prize_pool
            })
    }

    componentDidUpdate() {
    }

    editTournamentHandler = () => {
        const data = this.state;
        if (this.state.no_limit) {
            data.max_players = -1;
        }
        if (this.state.unlimited_entries) {
            data.max_entries_from_single_users = -1;
        }
        this.props.editSubGame(data);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.tournament != nextProps.tournament) {
            this.props.onClose()
        }
    }

    render() {
        const { classes } = this.props;
        return (

            <GridContainer className={classes.container}>
                <Card className={classes.card}>
                    <CardHeader color="warning" className={classes.cardHeader}>
                        <h4 className={classes.cardTitle}>Редактирай подигра</h4>
                    </CardHeader>
                    <Scrollbars style={{ height: "60vh" }}>
                        <CardBody>
                            <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <GridItem xs={12} sm={12} md={6}>
                                    <div style={!this.state.no_limit ? {} : { display: "none" }}>
                                        <CustomInput
                                            labelText="Максимален брой участници"
                                            id="max_players"
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "number",
                                                value: this.state.max_players,
                                                
                                                onChange: (event) => {
                                                    var max_players = event.target.value;
                                                    if (max_players < 0) {
                                                        max_players = 0;
                                                    }
                                                    if (max_players > Number.MAX_SAFE_INTEGER) {
                                                        max_players = Number.MAX_SAFE_INTEGER;
                                                    }
                                                    this.setState({ max_players: max_players })
                                                }
                                            }}
                                        />
                                    </div>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6} className={classes.grid} >
                                    <CustomCheckboxLabel
                                        isChecked={this.state.no_limit}
                                        labelName={"Без лимит на брой участници"}
                                        onChange={(event) => {
                                            this.setState({ no_limit: event.target.checked })
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <div style={this.state.manual_bet ? {} : { display: "none" }}>
                                        <CustomInput
                                            labelText="Награден фонд"
                                            id="total_prize_pool"
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "number",
                                                value: this.state.total_prize_pool,
                                                onChange: (event) => {
                                                    var total_prize_pool = event.target.value;
                                                    if (total_prize_pool < 0) {
                                                        total_prize_pool = 0;
                                                    }
                                                    if (total_prize_pool > Number.MAX_SAFE_INTEGER) {
                                                        total_prize_pool = Number.MAX_SAFE_INTEGER;
                                                    }
                                                    this.setState({ total_prize_pool: total_prize_pool })
                                                }
                                            }}
                                        />
                                    </div>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6} className={classes.grid} >
                                    <CustomCheckboxLabel
                                        isChecked={this.state.manual_bet}
                                        labelName={"Ръчно добавяне на награден фонд"}
                                        onChange={(event) => {
                                            this.setState({ manual_bet: event.target.checked })
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Залог за играта (BGN)"
                                        id="entry_fee"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.entry_fee,
                                            
                                            onChange: (event) => {
                                                var entry_fee = event.target.value;
                                                if (entry_fee < 0) {
                                                    entry_fee = 0;
                                                }
                                                if (entry_fee > Number.MAX_SAFE_INTEGER) {
                                                    entry_fee = Number.MAX_SAFE_INTEGER;
                                                }
                                                this.setState({ entry_fee: entry_fee })
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Процент печеливши участници (до 100)"
                                        id="number_of_prize_winners"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.number_of_prize_winners,
                                            error: this.state.error_number_of_prize_winners,
                                            onChange: (event) => {
                                                var number_of_prize_winners = event.target.value;
                                                if (number_of_prize_winners > 100 || number_of_prize_winners < 0) {
                                                    this.setState({error_number_of_prize_winners: true})
                                                } else {
                                                    this.setState({error_number_of_prize_winners: false})
                                                }
                                                this.setState({ number_of_prize_winners: number_of_prize_winners })
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Процент мин. печалба (от 100 до 500)"
                                        id="minimum_prize"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.minimum_prize,
                                            error: this.state.error_minimum_prize,
                                            onChange: (event) => {
                                                var minimum_prize = event.target.value;
                                                if (minimum_prize > 500 || minimum_prize < 100) {
                                                    this.setState({error_minimum_prize: true})
                                                    
                                                } else {
                                                    this.setState({error_minimum_prize: false})
                                                }
                                                
                                                this.setState({ minimum_prize: minimum_prize });
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Процент печалба за победителя от общия награден фонд (до 100)"
                                        id="top_prize"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.top_prize,
                                            error: this.state.error_top_prize,
                                            onChange: (event) => {
                                                var top_prize = event.target.value;
                                                if (top_prize > 100 || top_prize < 0) {
                                                    this.setState({error_top_prize: true})
                                                } else {
                                                    this.setState({error_top_prize: false})
                                                }
                                                this.setState({ top_prize: top_prize })
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Процент рейк (до 100)"
                                        id="percent_rake"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.percent_rake,
                                            error: this.state.error_percent_rake,
                                            onChange: (event) => {
                                                var percent_rake = event.target.value;
                                                if (percent_rake > 100 || percent_rake < 0) {
                                                    this.setState({error_percent_rake: true})
                                                } else {
                                                    this.setState({error_percent_rake: false})
                                                }
                                                this.setState({ percent_rake: percent_rake })
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}></GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <div style={this.state.unlimited_entries ? {display: "none"} : {  }}>
                                        <CustomInput
                                            labelText="Максимален брой входове от един потребител"
                                            id="max_entries_from_single_users"
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "number",
                                                value: this.state.max_entries_from_single_users,
                                                error: this.state.error_max_entries_from_single_users,
                                                onChange: (event) => {
                                                    var max_entries_from_single_users = event.target.value;
                                                    if (max_entries_from_single_users < 1) {
                                                        this.setState({ error_max_entries_from_single_users: true })
                                                    } else {
                                                        this.setState({ error_max_entries_from_single_users: false })
                                                    }
                                                    this.setState({ max_entries_from_single_users: max_entries_from_single_users })
                                                }
                                            }}
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid} >
                                    <CustomCheckboxLabel
                                        isChecked={this.state.unlimited_entries}
                                        labelName={"Без ограничение на входове от един потребител"}
                                        onChange={(event) => {
                                            this.setState({ unlimited_entries: event.target.checked })
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <Button
                                disabled={this.state.error_top_prize || this.state.error_minimum_prize || this.state.error_number_of_prize_winners}
                                style={{ marginTop: "30px" }}
                                onClick={this.editTournamentHandler}
                                color="success">
                                Запази промените
                            </Button>
                        </CardBody>
                    </Scrollbars>
                </Card>
            </GridContainer>

        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    }
});

const mapStateToProps = state => {

    return {
        tournament: state.championshipGames.tournament,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editSubGame: data => dispatch(editSubGame(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditSubGameForm));
