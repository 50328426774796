import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getMostBoughtPlayers = (data) => {
    return dispatch => {

        axios.post('/stats/mostBoughtPlayers', data)
            .then(res => {
                dispatch(setMostBoughtPlayers(res.data.data));
            });
    };
};

export const setMostBoughtPlayers = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_MOST_BOUGHT_PLAYERS,
        data: data
    };
};

export const clearFiltersMostBoughtPlayers = () => {
    return {
        type: actionTypes.CLEAR_FILTERS_MOST_BOUGHT_PLAYERS,
    }
}