import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

import {
    grayColor,
    primaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor,

} from "vendor/assets/jss/material-kit-react.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { blueColor, blueBoxShadow } from 'utils/Constants';


const paginationStyle = {
    pagination: {
        display: "inline-block",
        paddingLeft: "0",
        margin: "0 0 20px 0",
        borderRadius: "4px"
    },
    paginationItem: {
        display: "inline"
    },
    paginationLinkNoColor: {
        ":first-of-type": {
            marginleft: "0"
        },
        border: "0",
        borderRadius: "30px !important",
        transition: "all .3s",
        padding: "0px 11px",
        margin: "0 3px",
        minWidth: "30px",
        height: "30px",
        minHeight: "auto",
        lineHeight: "30px",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        background: "transparent",
        position: "relative",
        float: "left",
        textDecoration: "none",
        boxSizing: "border-box",
        color: "gray",
        // backgroundColor: blueColor,
        // "&,&:hover,&:focus": {
        //     color: "white"
        // },
        // "&:hover,&:focus": {
        //     zIndex: "3",
        //     backgroundColor: "#eee",
        //     borderColor: "#ddd"
        // },
        // "&:hover": {
        //     cursor: "pointer"
        // }
    },
    paginationLink: {
        ":first-of-type": {
            marginleft: "0"
        },
        border: "0",
        borderRadius: "30px !important",
        transition: "all .3s",
        padding: "0px 11px",
        margin: "0 3px",
        minWidth: "30px",
        height: "30px",
        minHeight: "auto",
        lineHeight: "30px",
        fontWeight: "400",
        fontSize: "12px",
        textTransform: "uppercase",
        background: "transparent",
        position: "relative",
        float: "left",
        textDecoration: "none",
        boxSizing: "border-box",
        color: "white",
        backgroundColor: warningColor,
        "&,&:hover,&:focus": {
            color: "white"
        },
        "&:hover,&:focus": {
            zIndex: "3",
            backgroundColor: warningColor,
            borderColor: "#ddd"
        },
        "&:hover": {
            cursor: "default"
        }
    },
    blue: {
        "&,&:hover,&:focus": {
            backgroundColor: blueColor,
            borderColor: blueColor,
            color: "white",
            boxShadow: blueBoxShadow
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "pointer"
        }
    },
    gray: {
        "&,&:hover,&:focus": {
            backgroundColor: grayColor,
            borderColor: grayColor,
            color: "white",
            boxShadow: blueBoxShadow
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "default"
        }
    },
    primary: {
        "&,&:hover,&:focus": {
            backgroundColor: primaryColor,
            borderColor: primaryColor,
            color: "#FFFFFF",
            boxShadow:
                "0 4px 5px 0 rgba(156, 39, 176, 0.14), 0 1px 10px 0 rgba(156, 39, 176, 0.12), 0 2px 4px -1px rgba(156, 39, 176, 0.2)"
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "default"
        }
    },
    info: {
        "&,&:hover,&:focus": {
            backgroundColor: infoColor,
            borderColor: infoColor,
            color: "#FFFFFF",
            boxShadow:
                "0 4px 5px 0 rgba(0, 188, 212, 0.14), 0 1px 10px 0 rgba(0, 188, 212, 0.12), 0 2px 4px -1px rgba(0, 188, 212, 0.2)"
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "default"
        }
    },
    success: {
        "&,&:hover,&:focus": {
            backgroundColor: successColor,
            borderColor: successColor,
            color: "#FFFFFF",
            boxShadow:
                "0 4px 5px 0 rgba(76, 175, 80, 0.14), 0 1px 10px 0 rgba(76, 175, 80, 0.12), 0 2px 4px -1px rgba(76, 175, 80, 0.2)"
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "default"
        }
    },
    warning: {
        "&,&:hover,&:focus": {
            backgroundColor: warningColor,
            borderColor: warningColor,
            color: "#FFFFFF",
            boxShadow:
                "0 4px 5px 0 rgba(255, 152, 0, 0.14), 0 1px 10px 0 rgba(255, 152, 0, 0.12), 0 2px 4px -1px rgba(255, 152, 0, 0.2)"
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "default"
        }
    },
    danger: {
        "&,&:hover,&:focus": {
            backgroundColor: dangerColor,
            borderColor: dangerColor,
            color: "#FFFFFF",
            boxShadow:
                "0 4px 5px 0 rgba(244, 67, 54, 0.14), 0 1px 10px 0 rgba(244, 67, 54, 0.12), 0 2px 4px -1px rgba(244, 67, 54, 0.2)"
        },
        "&:hover,&:focus": {
            zIndex: "2",
            cursor: "default"
        }
    },
    disabled: {
        "&,&:hover,&:focus": {
            color: "#777",
            cursor: "not-allowed",
            backgroundColor: "#fff",
            borderColor: "#ddd"
        }
    }
};

class Pagination extends React.Component {


    loadFirstPage = () => {
        if (this.props.currentPage !== 1) {
            this.props.changePageTo(1);
        }
    }

    loadPrevPage = () => {
        let page = this.props.currentPage - 1;
        if (page > 0) {
            this.props.changePageTo(page);
        }

    }

    loadNextPage = () => {
        let page = this.props.currentPage + 1;
        if (page <= this.props.totalPages) {
            this.props.changePageTo(page);
        }

    }

    loadLastPage = () => {
        if (this.props.currentPage !== this.props.totalPages) {
            this.props.changePageTo(this.props.totalPages);
        }

    }

    render() {
        const { classes, color, currentPage, totalPages } = this.props;
        let pagesPrev = [
            { onClick: this.loadFirstPage, active: this.props.currentPage === 1 ? false : true, text: "|<" },
            { onClick: this.loadPrevPage, active: this.props.currentPage === 1 ? false : true, text: "назад" }
        ];
        let pagesNext = [
            { onClick: this.loadNextPage, active: this.props.currentPage === this.props.totalPages ? false : true, text: "напред" },
            { onClick: this.loadLastPage, active: this.props.currentPage === this.props.totalPages ? false : true, text: ">|" }
        ];


        return (
            <ul className={classes.pagination}>


                {pagesPrev.map((prop, key) => {

                    return (
                        <li className={classes.paginationItem} key={key}>
                            <Button onClick={prop.onClick} className={[classes.paginationLink + " " + (prop.active === true ? color : classes.gray)]}>
                                {prop.text}
                            </Button>
                        </li>
                    );
                })}
                <li className={classes.paginationItem}>
                    <Button className={classes.paginationLinkNoColor}>{this.props.currentPage} oт {this.props.totalPages} </Button>
                </li>
                {pagesNext.map((prop, key) => {

                    return (
                        <li className={classes.paginationItem} key={key}>
                            <Button onClick={prop.onClick} className={[classes.paginationLink + " " + (prop.active === true ? color : classes.gray)]}>
                                {prop.text}
                            </Button>
                        </li>
                    );
                })}
            </ul>
        );
    }
}



export default withStyles(paginationStyle)(Pagination);
