import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
    root: {
        color: amber[600],
        '&$checked': {
            color: amber[500],
        },
    },
    checked: {},
};

class CustomCheckbox extends React.Component {

    render() {
        const { classes } = this.props;

        return (

            <Checkbox
                checked={this.props.isChecked}
                onChange={this.props.onChange}
                classes={{
                    root: classes.root,
                    checked: classes.checked,
                }}
            />

        );
    }
}

export default withStyles(styles)(CustomCheckbox);