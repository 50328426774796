import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import UploadImage from "UI/UploadImage/UploadImage"
import { darkBlueColor } from "utils/Constants.js";
import axios from "utils/axios";

import { connect } from 'react-redux';
import { addTeam } from "Pages/Teams/actions/teams.js";


class AddTeamForm extends React.Component {

    state = {
        name: "",
        short_name: "",
        logo: null,
        shirt: null,
        gk_shirt: null,
        diff_away: null,
        diff_host: null,
        diff_away_error: false,
        diff_host_error: false
    }

    nameChangedHandler = (event) => {
        this.setState({ name: event.target.value });
    }

    shortNameChangedHandler = (event) => {
        this.setState({ short_name: event.target.value });
    }

    logoChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/teams/logo', { base64: image }).then((response) => {
                this.setState({ logo: response.data.data });
            });
        }.bind(this);
    }

    shirtChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/teams/shirt', { base64: image }).then((response) => {

                this.setState({ shirt: response.data.data });
            });
        }.bind(this);
    }

    gkShirtChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/teams/shirt', { base64: image }).then((response) => {
                this.setState({ gk_shirt: response.data.data });
            });
        }.bind(this);
    }
    
    diffHostHandler = event => {
        this.setState({ diff_host: event.target.value });
    }

    diffAwayHandler = event => {
        this.setState({ diff_away: event.target.value });
    }

    addTeamHandler = () => {

        this.props.addTeam(this.state, this.props.championshipId)
        this.props.onClose()
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Профил</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Име"
                                        id="name"
                                        formControlProps={{}}
                                        inputProps={{
                                            value: this.state.name,
                                            onChange: (event) => this.nameChangedHandler(event)
                                        }}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Кратко име"
                                        id="short_name"
                                        formControlProps={{}}
                                        inputProps={{
                                            value: this.state.short_name,
                                            onChange: (event) => this.shortNameChangedHandler(event)
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4}>
                                    <UploadImage
                                        description="Качи лого"
                                        image={this.state.logo}
                                        onChange={this.logoChangedHandler}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <UploadImage
                                        description="Качи тениска"
                                        image={this.state.shirt}
                                        onChange={this.shirtChangedHandler}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <UploadImage
                                        description="Качи тениска на вратаря"
                                        image={this.state.gk_shirt}
                                        onChange={this.gkShirtChangedHandler}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Трудност като домакин"
                                        id="diff_host"
                                        formControlProps={{}}
                                        inputProps={{
                                            value: this.state.diff_host,
                                            error: this.state.diff_host_error,
                                            onChange: (event) => {
                                                if (event.target.value < 1 || event.target.value > 5) {
                                                    this.setState({diff_host_error: true})
                                                } else {
                                                    this.setState({diff_host_error: false})
                                                }
                                                this.diffHostHandler(event)
                                            }
                                        }}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Трудност като гост"
                                        id="diff_away"
                                        formControlProps={{}}
                                        inputProps={{
                                            value: this.state.diff_away,
                                            error: this.state.diff_away_error,
                                            onChange: (event) => {
                                                if (event.target.value < 1 || event.target.value > 5) {
                                                    this.setState({diff_away_error: true})
                                                } else {
                                                    this.setState({diff_away_error: false})
                                                }
                                                this.diffAwayHandler(event)
                                            }
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button
                                disabled={this.state.diff_away_error || this.state.diff_host_error}
                                onClick={this.addTeamHandler}
                                color="success">
                                Добави
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
    },
};


const mapDispatchToProps = dispatch => {
    return {
        addTeam: (team, championshipId) => dispatch(addTeam(team, championshipId))
    }
}


export default connect(null, mapDispatchToProps)(withStyles(styles)(AddTeamForm));
