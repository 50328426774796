import React from "react";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { dangerColor } from "vendor/assets/jss/material-dashboard-react.jsx";

const styles = theme => ({
    iconButton: {
        backgroundColor: dangerColor,
        color: "white",
        "&:hover": {
            backgroundColor: "rgba(244, 67, 54, 0.8)",
        }
    }

});

class AddButton extends React.Component {
    // state = {
    //     open: false
    // };

    // handleOpen = () => {
    //     this.setState({ open: true });
    // };

    // handleClose = () => {
    //     this.setState({ open: false });
    // };

    // componentWillReceiveProps(nextProps) {
    //     this.setState({ open: nextProps.open });
    // }


    render() {
        const { classes } = this.props;
        return (
            <Tooltip
                id="tooltip-top"
                title="Премахни"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
            >
                <IconButton
                    aria-label="Премахни"
                    className={classes.iconButton}
                    onClick={this.props.addHandler}
                >
                    <RemoveIcon
                        className={
                            classes.tableActionButtonIcon + " " + classes.edit
                        }
                    />
                </IconButton>
            </Tooltip>
        );
    }
}

export default withStyles(styles)(AddButton);
