import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getPopularCaptains = (data) => {
    return dispatch => {

        axios.post('/stats/popularCaptains', data)
            .then(res => {
                dispatch(setPopularCaptains(res.data.data));
            });
    };
};

export const setPopularCaptains = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_POPULAR_CAPTAINS,
        data: data
    };
};

export const clearFiltersPopularCaptains = () => {
    return {
        type: actionTypes.CLEAR_FILTERS_POPULAR_CAPTAINS,
    }
}