import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomSelect from "UI/CustomSelect/CustomSelect";
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import CustomPicker from "UI/CustomPicker/CustomPicker";
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { Scrollbars } from 'react-custom-scrollbars';
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import { darkBlueColor, getBadgeColorForRoundStatus, playoffPhases } from "utils/Constants"
import { getAllRounds } from 'Pages/Rounds/actions/rounds.js';
import { getAllTeamsForChampionship } from "Pages/Teams/actions/teams"
import { addGame } from "Pages/Games/actions/games"
import UploadImage from "UI/UploadImage/UploadImage"
import { connect } from 'react-redux';
import axios from "utils/axios";


class AddGameForm extends React.Component {

    state = {
        host_team: {
            name: "",
            id: 0
        },
        guest_team: {
            name: "",
            id: 0
        },
        round: {
            id: 0
        },
        start_date: null,
        playoff_phase: 0,
        host_coeff: null,
        guest_coeff: null,
        online_store_url: null,
        championship_logo: null
    }

    componentDidMount() {
        this.props.getAllTeamsForChampionship(this.props.championshipId);
        this.props.getAllRounds(this.props.championshipId);
        this.setState({
            championship_logo: this.props.championships.find(t => t.id == this.props.championshipId).logo
        })
    }

    hostTeamChangedHandler = (id) => {
        var selectedTeam = this.props.teams.find((t) => {
            return t.id === id;
        });

        this.setState({ host_team: selectedTeam });
    }

    guestTeamChangedHandler = (id) => {
        var selectedTeam = this.props.teams.find((t) => {
            return t.id === id;
        });

        this.setState({ guest_team: selectedTeam });
    }

    roundChangedHandler = (id) => {
        var selected = this.props.rounds.find((t) => {
            return t.id === id;
        });

        this.setState({ round: selected });
    }

    playoffChangeHandler = (id) => {
        var selected = playoffPhases.find((t) => {
            return t.id === id;
        });

        this.setState({ playoff_phase: selected.id });
    }

    dateChangedHandler = (date) => {
        const start_date = date.format("YYYY-MM-DD HH:mm:ss");
        this.setState({ start_date: start_date });
    }

    addGameHandler = () => {
        this.props.onClose()
        const data = {
            host_team: {
                id: this.state.host_team.id
            },
            guest_team: {
                id: this.state.guest_team.id
            },
            start_time: this.state.start_date,
            round: {
                id: this.state.round.id
            },
            online_store_url: this.state.online_store_url,
            host_coeff: this.state.host_coeff,
            guest_coeff: this.state.guest_coeff,
            championship_logo: this.state.championship_logo
        }
        this.props.addGame(data)
    }

    logoChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/files', { base64: image }).then((response) => {

                this.setState({ championship_logo: response.data.data });
            });
        }.bind(this);
    }

    render() {
        const { classes } = this.props;
        let mappedTeams = [];
        if (this.props.teams.length > 0) {
            mappedTeams = this.props.teams.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.logo} />
                            {t.name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedRounds = [];
        if (this.props.rounds.length > 0) {
            mappedRounds = this.props.rounds.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{t.name}</span>
                            <Badge color={getBadgeColorForRoundStatus(t.status.id)} >{t.status.name}</Badge>
                        </div>
                    ],
                    id: t.id
                };
            });
        }


        let mappedPlayoffPhases = [];
        if (playoffPhases.length > 0) {
            mappedPlayoffPhases = playoffPhases.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <span className={classes.badgeTitle}>{t.name}</span>
                        </div>
                    ],
                    id: t.id
                };
            });
        }




        return (

            <GridContainer className={classes.container}>
                <Card className={classes.card}>
                    <CardHeader color="warning" className={classes.cardHeader}>
                        <h4 className={classes.cardTitle}>Добави мач</h4>
                    </CardHeader>
                    <Scrollbars style={{ height: "60vh" }}>
                        <CardBody>
                            <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomPicker
                                        labelName="Начало"
                                        onChange={this.dateChangedHandler}
                                        date={this.state.start_date}
                                        fullWidth={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="Кръг"
                                        selectData={mappedRounds}
                                        value={this.state.round.id}
                                        fullWidth={true}
                                        onChange={this.roundChangedHandler}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="Домакин"
                                        selectData={mappedTeams}
                                        value={this.state.host_team.id}
                                        fullWidth={true}
                                        onChange={this.hostTeamChangedHandler}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="Гост"
                                        selectData={mappedTeams}
                                        onChange={this.guestTeamChangedHandler}
                                        fullWidth={true}
                                        value={this.state.guest_team.id}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomSelect
                                        labelName="Фаза"
                                        selectData={mappedPlayoffPhases}
                                        onChange={this.playoffChangeHandler}
                                        fullWidth={true}
                                        value={this.state.playoff_phase}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>

                                    <UploadImage
                                        description="Качи лого на шампионат"
                                        image={this.state.championship_logo}
                                        onChange={this.logoChangedHandler}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={1}></GridItem>
                                <GridItem xs={12} sm={12} md={5}>

                                    <OutlinedInput
                                        fullWidth={true}
                                        type={"number"}
                                        labelName="Коефициент домакин"
                                        value={this.state.host_coeff}
                                        onChange={(event) => this.setState({ host_coeff: event.target.value })} />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>

                                    <OutlinedInput
                                        fullWidth={true}
                                        labelName="Коефициент гост"
                                        value={this.state.guest_coeff}
                                        onChange={(event) => this.setState({ guest_coeff: event.target.value })} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1}></GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <OutlinedInput
                                        fullWidth={true}
                                        labelName="Линк на закупуване на билети"
                                        value={this.state.online_store_url}
                                        onChange={(event) => this.setState({ online_store_url: event.target.value })} />
                                </GridItem>

                            </GridContainer>
                        </CardBody>
                    </Scrollbars>
                    <CardFooter className={classes.cardFooter}>
                        <Button
                            onClick={this.addGameHandler}
                            color="success">
                            Добави
                            </Button>
                    </CardFooter>
                </Card>
            </GridContainer>

        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    }
});

const mapStateToProps = state => {
    return {
        teams: state.teams.teams,
        hostPlayers: state.games.hostTeamPlayers,
        guestPlayers: state.games.guestTeamPlayers,
        rounds: state.rounds.rounds,
        championships: state.championships.championships
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllTeamsForChampionship: id => dispatch(getAllTeamsForChampionship(id)),
        addGame: data => dispatch(addGame(data)),
        getAllRounds: (id) => dispatch(getAllRounds(id))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddGameForm));
