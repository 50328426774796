import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CustomPicker from "UI/CustomPicker/CustomPicker";
import Badge from "vendor/components/Badge/Badge.jsx"
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import List from "@material-ui/icons/List";

import CustomModal from "UI/CustomModal/CustomModal"
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"
import AddButton from "UI/CustomButtons/AddButton/AddButton"
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'

import { getTournamentById, getAllMatchesForChampionship, clearTournament, endGame } from "Pages/ChampionshipGames/actions/championshipGames";
import { getAllChampionships } from "Pages/Championships/actions/championships";
import { getBadgeColorForGameStatus, darkBlueColor, convertDateWithTime, convertTime } from 'utils/Constants'
import CustomCheckboxLabel from "UI/CustomCheckboxLabel/CustomCheckboxLabel"
import moment from "moment";
import { connect } from 'react-redux';
import Scrollbars from "react-custom-scrollbars";
import AddSubGameForm from "./AddSubGameForm/AddSubGameForm";
import ViewSubGameForm from "./ViewSubGameForm";
import OutlinedSelect from "../../../UI/OutlinedSelect/OutlinedSelect";

class ViewTournamentForm extends React.Component {

    state = {
        active_vice: false,
        active_captains: false,
        start_now: true,
        start_time: null,
        end_time: null,
        register_opened_start_time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        register_opened_end_time: null,
        is_active: false,
        championships: null,
        subgames: [],
        isAdded: false,
        addModalOpen: false,
        editModalOpen: false,
        confirmModalOpen: false,
        confirmMessage: "Сигурни ли сте, че искате да разпределите печалбите в играта?",
        selectedToEnd: null
    }


    componentDidMount() {
        this.props.getTournamentById(this.props.match.params.id);
    }

    componentWillUnmount() {
        this.props.clearTournament();
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.tournament != this.props.tournament) {
            this.setState({
                ...nextProps.tournament,
                championships: nextProps.tournament.championships.map(championship => {
                    var hasGames = false;
                    championship.games.forEach(game => {
                        if (game.is_selected) {
                            hasGames = true;
                        }
                    })
                    return { ...championship, checked: hasGames }
                })
            })
        }
    }

    editSubTournamentHandler = (t) => {
        this.setState({
            subGame: t,
            editModalOpen: true
        })
    }

    deleteSubTournamentHandler = (id) => {
        this.props.deleteSubTournament(id);
    }

    mapGamesForChampionship = championship => {
        let mappedTeams = [];
        const { classes } = this.props;
        //if (!this.props.allMatches) return <div></div>
        let matches = null;

        if (this.props.tournament.championships.find(t => t.id == championship.id)) {
            matches = this.props.tournament.championships.find(t => t.id == championship.id).games;
        }
        if (matches) {
            mappedTeams = matches.map(t => {
                const status = <Badge color={getBadgeColorForGameStatus(t.status.id)}>{t.status.name}</Badge>;
                const startTime = convertDateWithTime(t.start_time)//new Date(t.start_time).toLocaleString("en-US");
                const hostLogo = <ImageIcon image={t.host_team.logo} />
                const guestLogo = <ImageIcon image={t.guest_team.logo} />
                const result = t.status.id === 1 ? convertTime(t.start_time) : t.host_score + " : " + t.guest_score;
                const check = <CustomCheckboxLabel
                    isChecked={t.is_selected}
                    labelName={""}

                />;
                var link = t.online_store_url && <a
                    style={{ display: "block", width: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    target="_blank"
                    href={t.online_store_url}>
                    {t.online_store_url}
                </a>
                return {
                    tableData: [startTime, t.host_team.name, t.host_coeff, hostLogo, guestLogo, t.guest_coeff, t.guest_team.name, status, link, check],
                    id: t.id
                };
            });
        }

        return (<div style={{ width: "100%" }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="warning" className={classes.card}>
                            <ImageIcon image={championship.logo} />
                            <h4 className={classes.cardTitle}>{championship.name}</h4>
                            <CustomCheckboxLabel
                                rootClass={classes.rootClass}

                                isChecked={this.state.championships.find(u => u.id == championship.id).checked}
                                labelName={""}

                            />
                        </CardHeader>
                        <Scrollbars style={this.state.championships.find(u => u.id == championship.id).checked ? { height: "60vh" } : { height: "0vh" }}>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Начало", "Домакин", "Коеф.", "   ", "   ", "Коеф.", "Гост", "Статус", "Линк", "   "]}
                                    tableData={mappedTeams}
                                />
                            </CardBody>
                        </Scrollbars>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
        )
    }

    closeConfirmModalHandler = () => {
        this.setState({confirmModalOpen: false})
    }

    mapChampionships = championships => {
        return championships.map(t => this.mapGamesForChampionship(t))
    }

    mapSubTournaments = () => {
        const { classes } = this.props;
        let mappedSubTournaments = [];

        mappedSubTournaments = this.state.subgames.map(t => {
            const distributed = t.prizes_distributed;
            const endButton = <Button disabled={!(!distributed)} onClick={() => this.setState({selectedToEnd: t, confirmModalOpen: true})} color="success"> Завърши игра</Button>
            const edit = <TooltipButton labelName="Преглед" onClick={() => this.editSubTournamentHandler(t)}> <List /></TooltipButton>
            const maxPlayers = t.max_players > 0 ? t.max_players : "Без лимит"
            return {
                tableData: [maxPlayers, t.entry_fee, t.total_prize_pool, endButton, edit],
                id: t.id
            };
        });

        return (<div style={{ width: "100%" }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="warning" className={classes.card}>
                            <h4 className={classes.cardTitle}>Под-турнири: </h4>

                        </CardHeader>
                        <Scrollbars style={{ height: "60vh" }}>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Брой играчи", "Залог за влизане", "Награден фонд", "   ", "   "]}
                                    tableData={mappedSubTournaments}
                                />
                            </CardBody>
                        </Scrollbars>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>)
    }

    render() {
        const { classes, championshipGames } = this.props;
        console.log(this.state);
        if (!this.state.championships || !this.props.tournament) return <div />
        let mappedChampionships = this.mapChampionships(this.state.championships);
        let mappedSubTournaments = this.mapSubTournaments();

        let mappedParents = [];
        if (championshipGames.length > 0) {
            mappedParents = championshipGames.map(t => {
                return {
                    valueData: [
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.flag} />
                            {t.name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }
        return (
            <div>
                <CustomModal open={this.state.addModalOpen} close={this.closeAddModal}>
                    <AddSubGameForm onClose={this.closeAddModal} />
                </CustomModal>
                <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>
                    <ViewSubGameForm onClose={this.closeEditModal} subGame={this.state.subGame} />
                </CustomModal>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={() => {
                        this.props.endGame(this.state.selectedToEnd.id)
                        this.setState({selectedToEnd: null, confirmModalOpen: false})
                    }} />
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Преглед на игра</h4>
                        </CardHeader>

                        <CardBody>
                            <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        required={true}
                                        labelText="Име"
                                        id="name"
                                        formControlProps={{ fullWidth: false }}
                                        inputProps={{
                                            required: true,
                                            value: this.state.name,
                                            readOnly: true,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <ImageIcon big={true} image={this.state.flag} />
                                </GridItem>
                                {this.props.tournament.is_seasonal_game ?
                                    <GridItem xs={false} md={8}>
                                         <CustomInput
                                        labelText="Главен турнир"
                                        id="team_budget"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "text",
                                            value: championshipGames.find(game => game.id == this.props.tournament.main_tournament_id) ? championshipGames.find(game => game.id == this.props.tournament.main_tournament_id).name : null,
                                            readOnly: true
                                        }}
                                        />
                                    </GridItem> : null}
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomPicker
                                        labelName="Начало"
                                        emptyLabel=" "
                                        disabled={true}
                                        date={this.state.start_time}
                                        fullWidth={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomPicker
                                        labelName="Край"
                                        disabled={true}
                                        date={this.state.end_time}
                                        fullWidth={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomPicker
                                        labelName="Начало регистрация"
                                        disabled={true}
                                        date={this.state.register_opened_start_time}
                                        fullWidth={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomPicker
                                        labelName="Край регистрация"
                                        disabled={true}
                                        date={this.state.register_opened_end_time}
                                        fullWidth={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Бюджет"
                                        id="team_budget"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.team_budget,
                                            readOnly: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой играчи в един отбор"
                                        id="players_per_team_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.players_per_team_limit,
                                            readOnly: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой титулярни играчи"
                                        id="titulars_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.titulars_limit,
                                            readOnly: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой резерви"
                                        id="backup_players_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.backup_players_limit,
                                            readOnly: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput

                                        labelText="Максимален брой вратари"
                                        id="goalkeepers_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.goalkeepers_limit,
                                            readOnly: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой защитници"
                                        id="defenders_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.defenders_limit,
                                            readOnly: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой халфове"
                                        id="midfielders_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.midfielders_limit,
                                            readOnly: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой нападатели"
                                        id="attackers_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.attackers_limit,
                                            readOnly: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomCheckboxLabel
                                        isChecked={this.state.active_captains}
                                        labelName={"Активни капитани"}
                                    />

                                    <CustomCheckboxLabel
                                        isChecked={this.state.active_vice}
                                        labelName={"Активни вицекапитани"}
                                    />

                                </GridItem>
                                <Card>
                                    <CardHeader color="warning" className={classes.card}>
                                        <h4 className={classes.cardTitle}>Състояние</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomCheckboxLabel
                                                isChecked={this.state.is_active}
                                                labelName={"Активна"}
                                            />
                                            <CustomCheckboxLabel
                                                isChecked={this.state.is_hot}
                                                labelName={"HOT"}
                                            />
                                        </GridItem>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader color="warning" className={classes.card}>
                                        <h4 className={classes.cardTitle}>Правила</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomCheckboxLabel
                                                isChecked={this.state.stacking_penalty_active}
                                                labelName={"Stacking penalty"}
                                            />
                                            <CustomCheckboxLabel
                                                isChecked={this.state.safety_net_active}
                                                labelName={"Safety net"}
                                            />
                                        </GridItem>
                                    </CardBody>
                                </Card>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    {mappedChampionships}
                                    {mappedSubTournaments}
                                </div>
                            </GridContainer>
                        </CardBody>

                    </Card>
                </GridContainer>
            </div>
        );
    };


}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    }
});

const mapStateToProps = state => {

    return {
        tournament: state.championshipGames.tournament,
        championships: state.championships.championships,
        allMatches: state.championshipGames.allMatches,
        championshipGames: state.championshipGames.championshipGames,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTournamentById: id => dispatch(getTournamentById(id)),
        getAllChampionships: () => dispatch(getAllChampionships()),
        getAllMatchesForChampionship: (id, start_date, end_date) => dispatch(getAllMatchesForChampionship(id, start_date, end_date)),
        clearTournament: () => dispatch(clearTournament()),
        endGame: id => dispatch(endGame(id))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewTournamentForm));
