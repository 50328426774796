import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllAds = () => {
    return dispatch => {

        axios.get('/ads')
            .then(res => {
                dispatch(setAllAds(res.data.data));
            });
    };
};

export const setAllAds = (data) => {
    return {
        type: actionTypes.GET_ALL_ADS,
        data: data
    };
};

export const getAdPositions = () => {
    return dispatch => {

        axios.get('/ads/positions')
            .then(res => {
                dispatch(setAdPositions(res.data.data));
            });
    };
};

export const setAdPositions = (data) => {
    return {
        type: actionTypes.GET_AD_POSITIONS,
        data: data
    };
};

export const editAd = (id, data) => {
    return dispatch => {

        axios.put('/ads/' + id, data)
            .then(res => {
                dispatch(setEditedAd(res.data.data));
            });
    };
};

export const setEditedAd = (data) => {
    return {
        type: actionTypes.EDIT_AD,
        data: data
    };
};

export const addAd = (data) => {
    return dispatch => {

        axios.post('/ads', data)
            .then(res => {
                dispatch(setAddedAd(res.data.data));
            });
    };
};

export const setAddedAd = (data) => {
    return {
        type: actionTypes.ADD_AD,
        data: data
    };
};

export const deleteAd = (id) => {
    return dispatch => {

        axios.delete('/ads/' + id)
            .then(res => {
                dispatch(setDeletedAd(res.data.data));
            });
    };
};

export const setDeletedAd = (data) => {
    return {
        type: actionTypes.DELETE_AD,
        data: data
    };
};
