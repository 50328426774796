import { GET_STATISTICS_USED_CHIPS, CLEAR_FILTERS_USED_CHIPS } from 'Pages/Statistics/UsedChips/actions/actionTypes';


const initialState = {
    usedChips: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_USED_CHIPS:
            return {
                ...state,
                usedChips: action.data
            }

        case CLEAR_FILTERS_USED_CHIPS:
            return {
                ...state,
                usedChips: []
            }

        default:
            return state;
    }
}