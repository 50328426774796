/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "UI/Header/Header.jsx";
import Footer from "vendor/components/Footer/Footer.jsx";
import Sidebar from "UI/SideBar/SideBar.js";
import { withRouter } from 'react-router-dom';


import { statisticsRoutes } from "routes/appRoutes.jsx";
import dashboardStyle from "vendor/assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "vendor/assets/img/sidebar-4.jpg";
import logo from "assets/img/icon.png";

import TeamProfile from "Pages/Teams/screens/TeamProfile.js"
import GameStatsList from "Pages/GameStats/screens/GameStatsList.js"



class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false
        };
        this.resizeFunction = this.resizeFunction.bind(this);
    }

    componentWillMount() {
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
        if (!loggedUser) {
            this.props.history.index = 0;
            this.props.history.replace('/login');
        }

        //window.removeEventListener("resize", this.resizeFunction);
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    getRoute() {
        return this.props.location.pathname !== "/login";
    }
    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }
    render() {
        const { classes, ...rest } = this.props;
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));


        const switchRoutes = (

            <Switch>

                {statisticsRoutes.map((prop, key) => {
                   
                    if (prop.redirect && loggedUser)
                        return <Redirect from={prop.path} to={prop.to} key={key} />;
                    return <Route path={prop.path} component={prop.component} key={key} />;
                })}

            </Switch>
        );
        return (
            <div >


                <div ref="mainPanel">



                    {/* <div className={classes.content}> */}
                    <div className={classes.container}>{switchRoutes}</div>
                    {/* </div> */}


                </div>
            </div>
        );
    }
}

MainPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(dashboardStyle)(MainPage));
