import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { darkYellowColor } from "utils/Constants"

const styles = theme => ({
    root: {
        marginTop: "1em",
        marginBottom: "1em",
        height: "4em",
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: "12em",
        width: "100%",
        height: "4em",
        marginRight: "15px",
        marginTop: "0px"


    },
    textField: {
        // borderRadius: "40px",
        // backgroundColor: "white"
    },
    label: {
        transform: "translate(14px, 6px) scale(1)",
        '&$cssFocused $notchedOutline': {
            //color: `${ "red"} !important`
        },
        '&$outlined': {
            transform: "translate(14px, 6px) scale(1)",
        }

    },
    cssOutlinedInput: {
        height: "4em",
        '&$cssFocused $notchedOutline': {
            borderColor: `${darkYellowColor} !important`
        }
    },

    cssFocused: {
        color: `${darkYellowColor} !important`,

    },

    notchedOutline: {
        borderWidth: '1px',
        //borderRadius: "40px",
    },
    icon: {
        color: "lightGray"
    }
});

class SimpleSelect extends React.Component {
    state = {
        value: '',
    };

    handleChange = event => {
        this.props.onChange(event.target.value)
        this.setState({ value: event.target.value });
    };

    render() {
        const { classes } = this.props;

        let menuItems = this.props.selectData.map(item => {
            return <MenuItem key={item.id} value={item.id}> {item.valueData}</MenuItem>;
        });
        return (
            <form className={classes.root} autoComplete="off">
                <FormControl
                    fullWidth={this.props.fullWidth}
                    variant="outlined"
                    margin="normal"
                    className={classes.container}>
                    <InputLabel className={classes.label}
                        classes={{
                            focused: classes.cssFocused,
                        }}>
                        {this.props.labelName}

                    </InputLabel>
                    <Select
                        className={classes.textField}
                        disabled={this.props.disabled}
                        value={this.props.value}
                        onChange={this.handleChange}
                        classes={{
                            select: classes.cssFocused,
                            icon: classes.icon
                        }}
                        input={
                            <OutlinedInput
                                classes={{
                                    root: classes.cssOutlinedInput,
                                    focused: classes.cssFocused,
                                    notchedOutline: classes.notchedOutline
                                }}
                                labelWidth={100}
                                className={classes.notchedOutline}
                            />
                        }
                    >
                        {menuItems}
                    </Select>
                </FormControl>
            </form>
        );
    }
}

SimpleSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSelect);
