import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllTeams = () => {
    return dispatch => {

        axios.get('/teams')
            .then(res => {
                dispatch(setAllTeams(res.data.data));
            });
    };
};



export const setAllTeams = (teams) => {
    return {
        type: actionTypes.GET_ALL_TEAMS,
        teams: teams
    };
};

export const getAllTeamsForChampionship = (id) => {
    return dispatch => {

        axios.get("/championships/" + id + "/teams")
            .then(res => {
                dispatch(setAllTeams(res.data.data));
            });
    };
};



export const setAllTeamsForChampionship = (teams) => {
    return {
        type: actionTypes.GET_ALL_TEAMS_FOR_CHAMPIONSHIP,
        teams: teams
    };
};


export const getTeamData = (id) => {
    return dispatch => {

        axios.get('/teams/' + id)
            .then(res => {
                dispatch(setTeamData(res.data.data));
            });
    };
};



export const setTeamData = (edited) => {
    return {
        type: actionTypes.GET_TEAM_DATA,
        editedTeam: edited
    };
};

export const deleteTeam = (id, champId) => {
    return dispatch => {

        axios.delete('/teams/' + id)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setDeletedTeam(id));
                }
            });
    };
};

export const setDeletedTeam = (id) => {
    return {
        type: actionTypes.DELETE_TEAM,
        teamId: id
    };
};

export const addTeam = (team, champId) => {
    return dispatch => {

        axios.post('/teams', team)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setAddedTeam(res.data.data));
                    dispatch(addTeamToChampionship(res.data.data.id, champId))
                }
            });
    };
};

export const addTeamToChampionship = (teamId, champId) => {
    return dispatch => {
        axios.post('/championships/teams', {
            "championship_id": champId,
            "team_id": teamId
        }).then(res => { });
    };
};

export const setAddedTeam = (team) => {
    return {
        type: actionTypes.ADD_TEAM,
        team: team
    };
};

export const editTeam = (team) => {
    return dispatch => {

        axios.put('/teams', team)
            .then(res => {
                if (res.data.has_error === false) {
                    dispatch(setEditedTeam(res.data.data));
                }
            });
    };
};



export const setEditedTeam = (team) => {
    return {
        type: actionTypes.EDIT_TEAM,
        team: team
    };
};