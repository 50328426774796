import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getWildcards = (data) => {
    return dispatch => {

        axios.post('/stats/wildcards', data)
            .then(res => {
                dispatch(setWildcards(res.data.data));
            });
    };
};

export const setWildcards = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_WILDCARDS,
        data: data
    };
};

export const clearFiltersWildcards = () => {
    return {
        type: actionTypes.CLEAR_FILTERS_WILDCARDS,
    }
}