import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { darkBlueColor, getBadgeColorForGameStatus, playoffPhases } from "utils/Constants"
import { editUser } from "Pages/Statistics/Users/actions/users"
import { connect } from 'react-redux';
import PersonOutline from "@material-ui/icons/PersonOutline";
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";


class EditUserForm extends React.Component {

    state = this.props.user;

    componentDidMount() {

    }

    handleChangeInput = (name) => event => {

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    editHandler = () => {
        this.props.onClose()
        const data = {
            id: this.state.id,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            password: this.state.password,
            email: this.state.email
        }
        this.props.editUser(data)
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Редактирай играч</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <OutlinedInput
                                        fullWidth={true}
                                        icon={<PersonOutline className={classes.inputIconsColor} />}
                                        labelName="Първо име"
                                        value={this.state.first_name}
                                        onChange={this.handleChangeInput("first_name")} />

                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <OutlinedInput
                                        fullWidth={true}
                                        icon={<PersonOutline className={classes.inputIconsColor} />}
                                        labelName="Фамилия"
                                        value={this.state.last_name}
                                        onChange={this.handleChangeInput("last_name")} />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <OutlinedInput
                                        fullWidth={true}
                                        icon={<PersonOutline className={classes.inputIconsColor} />}
                                        labelName="Email"
                                        value={this.state.email}
                                        onChange={this.handleChangeInput("email")} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <OutlinedInput
                                        fullWidth={true}
                                        icon={<PersonOutline className={classes.inputIconsColor} />}
                                        labelName="Парола"
                                        value={this.state.password}
                                        onChange={this.handleChangeInput("password")} />
                                </GridItem>

                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button
                                onClick={this.editHandler}
                                color="warning">
                                Редактирай
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = {
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    }
};

const mapStateToProps = state => {

    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editUser: data => dispatch(editUser(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditUserForm));
