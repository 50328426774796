import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CustomPicker from "UI/CustomPicker/CustomPicker";
import Badge from "vendor/components/Badge/Badge.jsx"
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CustomModal from "UI/CustomModal/CustomModal"
import AddButton from "UI/CustomButtons/AddButton/AddButton"
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'

import { addTournament, getAllMatchesForChampionship, editTournament, deleteSubTournament, clearTournament, getAllChampionshipGames } from "Pages/ChampionshipGames/actions/championshipGames";
import { getAllChampionships } from "Pages/Championships/actions/championships";
import { getBadgeColorForGameStatus, darkBlueColor, convertDateWithTime, convertTime } from 'utils/Constants'
import CustomCheckboxLabel from "UI/CustomCheckboxLabel/CustomCheckboxLabel"
import moment from "moment";
import { connect } from 'react-redux';
import Scrollbars from "react-custom-scrollbars";
import AddSubGameForm from "./AddSubGameForm/AddSubGameForm"
import EditSubGameForm from "./EditSubGameForm";
import UploadImage from "UI/UploadImage/UploadImage"
import axios from "utils/axios";
import OutlinedSelect from '../../../UI/OutlinedSelect/OutlinedSelect';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getAllRounds } from '../../Rounds/actions/rounds';


class AddTournamentForm extends React.Component {

    state = {
        active_vice: false,
        active_captains: false,
        start_now: true,
        start_register_time_with_game: true,
        start_time: null,
        end_time: null,
        register_opened_start_time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        register_opened_end_time: null,
        is_active: false,
        is_hot: false,
        stacking_penalty: false,
        safety_net: false,
        championships: null,
        subgames: [],
        is_seasonal_game: false,
        is_seasonal_game_round: {}
    }

    componentDidMount() {
        this.props.getAllChampionships();
        this.props.getAllChampionshipGames();
    }

    componentWillUnmount() {
        this.props.clearTournament();
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.championships && nextProps.championships) {
            this.setState({
                active_vice: false,
                active_captains: false,
                start_now: true,
                start_time: null,
                end_time: null,
                register_opened_start_time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                register_opened_end_time: null,
                is_active: false,
                championships: nextProps.championships.map(t => { return { ...t, checked: false } })
            })
        }
        if (nextProps.tournament && nextProps.tournament.id) {
            this.setState({ gameAdded: true, id: nextProps.tournament.id })
        }
        if (nextProps.tournament != this.props.tournament) {
            if (!this.props.tournament) {
                this.setState({
                    ...nextProps.tournament,
                    championships: nextProps.tournament.championships.map(championship => {
                        var hasGames = false;
                        championship.games.forEach(game => {
                            if (game.is_selected) {
                                hasGames = true;
                            }
                        })
                        return { ...championship, checked: hasGames }
                    })
                })
            }
        }
        if (nextProps.tournament && this.props.tournament && nextProps.tournament != this.props.tournament) {
            this.setState({
                ...this.state,
                subgames: nextProps.tournament.subgames,
                championships: nextProps.tournament.championships.map(championship => {
                    var hasGames = false;

                    championship.games.forEach(game => {
                        if (game.is_selected) {
                            hasGames = true;
                        }
                    })
                    return { ...championship, checked: hasGames }
                })
            })
        }
    }

    changeFlagIfNeeded = (championships) => {
        if (this.state.gameAdded || this.state.flagAdded) return
        var hasGamesFromChamp = [];
        championships.forEach((championship, index) => {
            hasGamesFromChamp.push({ championship_id: championship.id, hasGames: false });
            championship.games.forEach(game => {
                if (game.is_selected) {
                    hasGamesFromChamp[index] = { championship_id: championship.id, hasGames: true };
                }
            })
        })
        var flag = null;
        if (hasGamesFromChamp[0].hasGames && !hasGamesFromChamp[1].hasGames && !hasGamesFromChamp[2].hasGames) {
            flag = this.props.championships.find(t => t.id == hasGamesFromChamp[0].championship_id).flag;
        } else if (!hasGamesFromChamp[0].hasGames && hasGamesFromChamp[1].hasGames && !hasGamesFromChamp[2].hasGames) {
            flag = this.props.championships.find(t => t.id == hasGamesFromChamp[1].championship_id).flag;

        } else if (!hasGamesFromChamp[0].hasGames && !hasGamesFromChamp[1].hasGames && !hasGamesFromChamp[2].hasGames) {
            flag = null
        } else {
            flag = this.props.championships.find(t => t.id == hasGamesFromChamp[2].championship_id).flag;
        }
        this.setState({ flag })
    }

    dateChangedHandler = (date, name) => {
        this.setState({ [name]: date.format("YYYY-MM-DD HH:mm:ss") });
    }

    addGameHandler = () => {
        var data = this.state;
        if (this.state.start_register_time_with_game) {
            data.register_opened_end_time = data.start_time;

        }
        data.championships = data.championships.map(championship => {
            const games = [];
            championship.games.forEach(t => {
                if (t.is_selected) {
                    games.push(t);
                }
            })
            if (games.length > 0) {
                return { ...championship, games }
            }
            return null;
        }).filter(t => t != null);
       
        !this.state.gameAdded ?
            this.props.addTournament(data)
            : this.props.editTournament(data);
    }

    captainSwitchHandler = (event, isCaptain) => {
        if (event.target.checked && !isCaptain && !this.state.active_captains) {
            return;
        } else if (!event.target.checked && isCaptain && this.state.active_vice) {
            this.setState({
                active_vice: false,
                active_captains: false
            })
        } else {
            const name = isCaptain ? "active_captains" : "active_vice";
            this.setState({ [name]: event.target.checked })
        }
    }

    mapGamesForChampionship = championship => {
        let mappedTeams = [];
        const { classes } = this.props;
        if (!this.props.allMatches) return <div></div>

        const championshipInState = this.state.championships.find(t => t.id == championship.id);
        if (championshipInState && championshipInState.games) {
            mappedTeams = championshipInState.games.map(t => {
                const status = <Badge color={getBadgeColorForGameStatus(t.status.id)}>{t.status.name}</Badge>;
                const startTime = convertDateWithTime(t.start_time)
                const hostLogo = <ImageIcon image={t.host_team.logo} />
                const guestLogo = <ImageIcon image={t.guest_team.logo} />
                const check = <CustomCheckboxLabel
                    isChecked={t.is_selected}
                    labelName={""}
                    onChange={(event) => {
                        const newChampionships = this.state.championships.map(u => {
                            if (u.id == championship.id) {
                                var games = u.games;
                                games = games.map(game => {
                                    if (game.id == t.id) {
                                        return { ...game, is_selected: event.target.checked }
                                    }
                                    return { ...game }
                                })
                                return { ...u, games }
                            }
                            return { ...u }
                        })
                        this.setState({
                            championships: newChampionships
                        })
                        this.changeFlagIfNeeded(newChampionships);
                    }}
                />;
                var link = t.online_store_url && <a
                    style={{ display: "block", width: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    target="_blank"
                    href={t.online_store_url}>
                    {t.online_store_url}
                </a>
                return {
                    tableData: [startTime, t.host_team.name, t.host_coeff, hostLogo, guestLogo, t.guest_coeff, t.guest_team.name, status, link, check],
                    id: t.id,
                    is_selected: t.is_selected
                };
            });
        }

        return (<div style={{ width: "100%" }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="warning" className={classes.card}>
                            <ImageIcon image={championship.logo} />
                            <h4 className={classes.cardTitle}>{championship.name}</h4>
                            <div style={{ cursor: "pointer" }}>
                                <KeyboardArrowDownIcon onClick={() => {
                                    this.setState({
                                        championships: this.state.championships.map(u => {
                                            if (u.id == championship.id) {
                                                return { ...u, checked: !u.checked }
                                            }
                                            return { ...u }
                                        })
                                    })
                                }} />
                            </div>
                        </CardHeader>
                        <Scrollbars style={championship.checked ? { height: "60vh" } : { height: "0vh" }}>
                            <CardBody>
                                <TableWithActionButtons
                                    shouldCheckUpdate={true}
                                    tableHeaderColor="gray"
                                    tableHead={["Начало", "Домакин", "Коеф.", "   ", "   ", "Коеф.", "Гост", "Статус", "Линк", "Избери"]}
                                    tableData={mappedTeams}
                                />
                            </CardBody>
                        </Scrollbars>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
        )
    }

    mapChampionships = championships => {
        return championships.map(t => this.mapGamesForChampionship(t))
    }

    editSubTournamentHandler = (t) => {
        this.setState({
            subGame: t,
            editModalOpen: true
        })
    }

    closeEditModal = () => {
        this.setState({ editModalOpen: false })
    }

    closeAddModal = () => {
        this.setState({ addModalOpen: false })
    }

    deleteSubTournamentHandler = (id) => {
        this.props.deleteSubTournament(id);
    }

    addSubGameHandler = () => {
        this.setState({ addModalOpen: true })
    }

    logoChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/files', { base64: image }).then((response) => {

                this.setState({ flag: response.data.data, flagAdded: true });
            });
        }.bind(this);
    }

    mapSubTournaments = () => {
        const { classes } = this.props;
        let mappedSubTournaments = [];
        if (!this.state.gameAdded) {
            return null;
        }
        mappedSubTournaments = this.state.subgames.map(t => {

            const edit = <TooltipButton labelName="Редактирай" onClick={() => this.editSubTournamentHandler(t)}> <Edit /></TooltipButton>
            const close = <TooltipButton labelName="Изтрий" onClick={() => this.deleteSubTournamentHandler(t.id)}> <Close /></TooltipButton>
            const maxPlayers = t.max_players > 0 ? t.max_players : "Без лимит"
            return {
                tableData: [maxPlayers, t.entry_fee, t.total_prize_pool, edit, close],
                id: t.id
            };
        });

        return (<div style={{ width: "100%" }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="warning" className={classes.card}>
                            <h4 className={classes.cardTitle}>Под-турнири: </h4>
                            <AddButton labelName="Добави подигра" onClick={this.addSubGameHandler} />
                        </CardHeader>
                        <Scrollbars style={{ height: "60vh" }}>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Брой играчи", "Залог за влизане", "Награден фонд", "   ", "   "]}
                                    tableData={mappedSubTournaments}
                                />
                            </CardBody>
                        </Scrollbars>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>)
    }

    render() {
        const { classes, championships, championshipGames } = this.props;
        if (!championships || !this.state.championships || !championshipGames) return <div />
        let mappedChampionships = this.mapChampionships(this.state.championships);
        let mappedSubTournaments = this.mapSubTournaments();
        
        let mappedParents = [];
        if (championshipGames.length > 0) {
            mappedParents = championshipGames.map(t => {
                return {
                    valueData: [
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}} key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.flag} />
                            {t.name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <CustomModal open={this.state.addModalOpen} close={this.closeAddModal}>
                    <AddSubGameForm onClose={this.closeAddModal} tournamentId={this.props.tournament ? this.props.tournament.id : null} />
                </CustomModal>
                <CustomModal open={this.state.editModalOpen} close={this.closeEditModal}>
                    <EditSubGameForm onClose={this.closeEditModal} subGame={this.state.subGame} />
                </CustomModal>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Добави игра</h4>
                        </CardHeader>

                        <CardBody>
                            <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        required={true}
                                        labelText="Име"
                                        id="name"
                                        formControlProps={{ fullWidth: false }}
                                        inputProps={{
                                            required: true,
                                            value: this.state.name,
                                            onChange: (date) => this.setState({ name: date.target.value })
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <UploadImage
                                        description="Качи флаг на шампионат"
                                        image={this.state.flag}
                                        onChange={this.logoChangedHandler}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomCheckboxLabel
                                      isChecked={this.state.is_seasonal_game}
                                      labelName="Сезонна Игра"
                                      onChange={() => {
                                          this.props.getAllRounds(4);

                                          this.setState({
                                              is_seasonal_game: !this.state.is_seasonal_game,
                                              is_seasonal_game_round: {}
                                          })
                                      }}
                                    />
                                </GridItem>
                                
                                {this.state.is_seasonal_game && (
                                  <React.Fragment>
                                      <GridItem xs={false} md={8}> 
                                      <OutlinedSelect
                                            labelName="Главен турнир"
                                            selectData={mappedParents}
                                            value={this.state.main_tournament_id}
                                            onChange={(id) => {this.setState({main_tournament_id: id})}}
                                        />
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={4}>
                                          <TextField
                                            select
                                            fullWidth
                                            label="Кръг"
                                            variant="outlined"
                                            value={this.state.is_seasonal_game_round.id}
                                            onChange={(e) => {
                                                const selectedRound = this.props.rounds.find(round => round.id === e.target.value);

                                                this.setState({
                                                    is_seasonal_game_round: selectedRound
                                                });

                                                const startDateArray = selectedRound.start_date.split(/-|\s|:/);
                                                const endDateArray = selectedRound.end_date.split(/-|\s|:/);

                                                this.dateChangedHandler(
                                                  moment(new Date(startDateArray[0], startDateArray[1] -1, startDateArray[2], startDateArray[3], startDateArray[4], startDateArray[5])), "start_time");
                                                this.dateChangedHandler(
                                                  moment(new Date(endDateArray[0], endDateArray[1] -1, endDateArray[2], endDateArray[3], endDateArray[4], endDateArray[5])), "end_time");

                                                setTimeout(() => {
                                                    this.props.getAllMatchesForChampionship(this.state.id, this.state.start_time, this.state.end_time);
                                                }, 1000);
                                            }}
                                          >
                                              {this.props.rounds.map((round) => (
                                                <MenuItem
                                                  key={round.id}
                                                  value={round.id}
                                                >
                                                    {round.name}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                      </GridItem>
                                      <GridItem xs={false} md={4} />
                                  </React.Fragment>
                                )}

                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomPicker
                                        disabled={this.state.is_seasonal_game}
                                        labelName="Начало"
                                        emptyLabel=" "
                                        onChange={(date) => {

                                            this.dateChangedHandler(date, "start_time")
                                            this.props.getAllMatchesForChampionship(this.state.id, date.format("YYYY-MM-DD HH:mm:ss"), this.state.end_time);
                                        }}
                                        date={this.state.start_time}
                                        fullWidth={true}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <CustomPicker
                                        disabled={this.state.is_seasonal_game}
                                        labelName="Край"
                                        onChange={(date) => {
                                            this.dateChangedHandler(date, "end_time")
                                            console.log(this.state);
                                            this.props.getAllMatchesForChampionship(this.state.id, this.state.start_time, date.format("YYYY-MM-DD HH:mm:ss"));
                                        }}
                                        date={this.state.end_time}
                                        fullWidth={true}
                                    />
                                </GridItem>

                                {!this.state.is_seasonal_game && (
                                  <React.Fragment>
                                      <GridItem xs={12} sm={12} md={6} className={classes.grid} style={{ marginTop: "30px", marginBottom: "-15px", marginLeft: "-20px" }}>
                                          <CustomCheckboxLabel
                                            isChecked={this.state.start_now}
                                            labelName={"Стартирай регистрация сега"}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    this.setState({
                                                        register_opened_start_time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                                                    })
                                                }
                                                this.setState({ start_now: event.target.checked })
                                            }}
                                          />
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={6} className={classes.grid} style={{ marginTop: "30px", marginBottom: "-15px", marginLeft: "-20px" }}>
                                          <CustomCheckboxLabel
                                            isChecked={this.state.start_register_time_with_game}
                                            labelName={"Край на регистрация при начало на игра"}
                                            onChange={(event) => {
                                                this.setState({ start_register_time_with_game: event.target.checked })
                                            }}
                                          />
                                      </GridItem>
                                  </React.Fragment>
                                )}
                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <div style={this.state.start_now ? { display: "none" } : {}}>
                                        <CustomPicker
                                            labelName="Начало регистрация"
                                            onChange={(date) => {
                                                this.setState({ start_now: false })
                                                this.dateChangedHandler(date, "register_opened_start_time")
                                            }}
                                            date={this.state.register_opened_start_time}
                                            fullWidth={true}
                                        />
                                    </div>
                                </GridItem>


                                <GridItem xs={12} sm={12} md={6} className={classes.grid}>
                                    <div style={this.state.start_register_time_with_game ? { display: "none" } : {}}>
                                        <CustomPicker
                                            labelName="Край регистрация"
                                            onChange={(date) => this.dateChangedHandler(date, "register_opened_end_time")}
                                            date={this.state.register_opened_end_time}
                                            fullWidth={true}
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Бюджет"
                                        id="team_budget"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.team_budget,
                                            onChange: (event) => {
                                                var budget = event.target.value;
                                                if (budget < 0) {
                                                    budget = 0;
                                                }
                                                this.setState({ team_budget: budget })
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой играчи от един състав"
                                        id="players_per_team_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.players_per_team_limit,
                                            onChange: (event) => {
                                                var playersLimit = event.target.value;
                                                if (playersLimit < 0) {
                                                    playersLimit = 0;
                                                } else if (playersLimit > 15) {
                                                    playersLimit = 15;
                                                }
                                                this.setState({ players_per_team_limit: playersLimit })
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой титулярни играчи"
                                        id="titulars_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.titulars_limit,
                                            onChange: (event) => {
                                                var mainPlayers = event.target.value;
                                                if (mainPlayers < 0) {
                                                    mainPlayers = 0;
                                                } else if (mainPlayers > 11) {
                                                    mainPlayers = 11;
                                                }
                                                this.setState({ titulars_limit: mainPlayers });
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой резерви"
                                        id="backup_players_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.backup_players_limit,
                                            onChange: (event) => {
                                                var backupPlayers = event.target.value;
                                                if (backupPlayers < 0) {
                                                    backupPlayers = 0;
                                                } else if (backupPlayers > 4) {
                                                    backupPlayers = 4;
                                                }
                                                this.setState({ backup_players_limit: backupPlayers })
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой вратари"
                                        id="goalkeepers_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.goalkeepers_limit,
                                            onChange: (event) => {
                                                var goalKeepers = event.target.value;
                                                if (goalKeepers < 0) {
                                                    goalKeepers = 0;
                                                } else if (goalKeepers > 2) {
                                                    goalKeepers = 2;
                                                }
                                                this.setState({ goalkeepers_limit: goalKeepers });
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой защитници"
                                        id="defenders_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.defenders_limit,
                                            onChange: (event) => {
                                                var defenders = event.target.value;
                                                if (defenders < 0) {
                                                    defenders = 0;
                                                } else if (defenders > 5) {
                                                    defenders = 5;
                                                }
                                                this.setState({ defenders_limit: defenders });
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой халфове"
                                        id="midfielders_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.midfielders_limit,
                                            onChange: (event) => {
                                                var midfielders = event.target.value;
                                                if (midfielders < 0) {
                                                    midfielders = 0;
                                                } else if (midfielders > 5) {
                                                    midfielders = 5;
                                                }
                                                this.setState({ midfielders_limit: midfielders });
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Максимален брой нападатели"
                                        id="attackers_limit"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.attackers_limit,
                                            onChange: (event) => {
                                                var attackers = event.target.value;
                                                if (attackers < 0) {
                                                    attackers = 0;
                                                } else if (attackers > 5) {
                                                    attackers = 5;
                                                }
                                                this.setState({ attackers_limit: attackers });
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomCheckboxLabel
                                        isChecked={this.state.active_captains}
                                        labelName={"Активни капитани"}
                                        onChange={(event) => this.captainSwitchHandler(event, true)}
                                    />

                                    <CustomCheckboxLabel
                                        isChecked={this.state.active_vice}
                                        labelName={"Активни вицекапитани"}
                                        onChange={(event) => this.captainSwitchHandler(event, false)}
                                    />

                                </GridItem>
                                <Card>
                                    <CardHeader color="warning" className={classes.card}>
                                        <h4 className={classes.cardTitle}>Състояние</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridItem xs={12} sm={12} md={12}>
                                            {this.state.gameAdded && <CustomCheckboxLabel
                                                isChecked={this.state.is_active}
                                                labelName={"Активна"}
                                                onChange={(event) => this.setState({ is_active: event.target.checked })}
                                            />}
                                            <CustomCheckboxLabel
                                                isChecked={this.state.is_hot}
                                                labelName={"HOT"}
                                                onChange={(event) => this.setState({ is_hot: event.target.checked })}
                                            />
                                        </GridItem>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardHeader color="warning" className={classes.card}>
                                        <h4 className={classes.cardTitle}>Правила</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomCheckboxLabel
                                                isChecked={this.state.stacking_penalty_active}
                                                labelName={"Stacking penalty"}
                                                onChange={(event) => this.setState({ stacking_penalty_active: event.target.checked })}
                                            />
                                            <CustomCheckboxLabel
                                                isChecked={this.state.safety_net_active}
                                                labelName={"Safety net"}
                                                onChange={(event) => this.setState({ safety_net_active: event.target.checked })}
                                            />
                                        </GridItem>
                                    </CardBody>
                                </Card>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    {mappedChampionships}
                                    {mappedSubTournaments}
                                </div>
                            </GridContainer>
                            <Button
                                style={{ marginTop: "30px" }}
                                onClick={this.addGameHandler}
                                color="success">
                                {"Запази промените"}
                            </Button>
                        </CardBody>

                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "90%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    },
    rootClass: {
        color: "white !important",
        '&$checked': {
            color: "white !important",
        },
    }
});

const mapStateToProps = state => {
    return {
        championships: state.championships.championships,
        allMatches: state.championshipGames.allMatches,
        championshipsToAdd: state.championships.championshipsToAdd,
        championshipGames: state.championshipGames.championshipGames,
        tournament: state.championshipGames.tournament,
        rounds: state.rounds.rounds,
        championshipGames: state.championshipGames.championshipGames,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addTournament: data => dispatch(addTournament(data)),
        getAllChampionships: () => dispatch(getAllChampionships()),
        getAllMatchesForChampionship: (id, start_date, end_date) => dispatch(getAllMatchesForChampionship(id, start_date, end_date)),
        editTournament: data => dispatch(editTournament(data)),
        deleteSubTournament: id => dispatch(deleteSubTournament(id)),
        clearTournament: () => dispatch(clearTournament()),
        getAllRounds: (id) => dispatch(getAllRounds(id)),
        getAllChampionshipGames: () => dispatch(getAllChampionshipGames()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddTournamentForm));
