import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from 'Toasts/actions/actionTypes';

const addNotification = (id, requestStatus) => {
    return { type: ADD_NOTIFICATION, payload: { id: id, data: requestStatus } }
}

const removeNotification = (id) => {
    return { type: REMOVE_NOTIFICATION, payload: id }
}

let nextNotificationId = 0;

export const showNotification = (requestStatus) => {
    return function (dispatch) {
        const id = nextNotificationId++;
        dispatch(addNotification(id, requestStatus));
        setTimeout(function () {
            dispatch(removeNotification(id));
        }, 10000);
    }
}