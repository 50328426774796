import { GET_STATISTICS_WINNERS, } from 'Pages/Statistics/BestPerformingUsers/actions/actionTypes';


const initialState = {
    winners: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_WINNERS:
            return {
                ...state,
                winners: action.data
            }


        default:
            return state;
    }
}