import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import { Scrollbars } from 'react-custom-scrollbars';
import { successColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import { darkBlueColor } from "utils/Constants"
import { editSubGame } from "Pages/ChampionshipGames/actions/championshipGames";
import CustomCheckboxLabel from "UI/CustomCheckboxLabel/CustomCheckboxLabel"
import { connect } from 'react-redux';


class ViewSubGameForm extends React.Component {

    state = {
        no_limit: false,
        manual_bet: false,
        unlimited_entries: false
    }

    componentDidMount() {
        this.setState(
            {
                ...this.state,
                ...this.props.subGame,
                no_limit: this.props.subGame.max_players == -1,
                unlimited_entries: this.props.subGame.max_entries_from_single_users == -1,
                manual_bet: this.props.subGame.manual_prize_pool
            })
    }

    render() {
        const { classes } = this.props;
        return (

            <GridContainer className={classes.container}>
                <Card className={classes.card}>
                    <CardHeader color="warning" className={classes.cardHeader}>
                        <h4 className={classes.cardTitle}>Преглед подигра</h4>
                    </CardHeader>
                    <Scrollbars style={{ height: "60vh" }}>
                        <CardBody>
                            <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <GridItem xs={12} sm={12} md={6}>
                                    <div style={!this.state.no_limit ? {} : { display: "none" }}>
                                        <CustomInput
                                            disabled={this.state.no_limit}
                                            labelText="Максимален брой участници"
                                            id="max_players"
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "number",
                                                value: this.state.max_players,
                                                disabled: this.state.no_limit,
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid} >
                                    <CustomCheckboxLabel
                                        isChecked={this.state.no_limit}
                                        labelName={"Без лимит на брой участници"}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <div style={this.state.manual_bet ? {} : { display: "none" }}>
                                        <CustomInput
                                            labelText="Награден фонд"
                                            id="total_prize_pool"
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "number",
                                                value: this.state.total_prize_pool,
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid} >
                                    <CustomCheckboxLabel
                                        isChecked={this.state.manual_bet}
                                        labelName={"Ръчно добавяне на награден фонд"}

                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Залог за играта (BGN)"
                                        id="entry_fee"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.entry_fee,
                                            readOnly: true,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Процент печеливши участници (до 100)"
                                        id="number_of_prize_winners"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.number_of_prize_winners,
                                            readOnly: true,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Процент мин. печалба (от 100 до 500)"
                                        id="minimum_prize"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.minimum_prize,
                                            readOnly: true,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Процент печалба за победителя от общия награден фонд (до 100)"
                                        id="top_prize"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.top_prize,
                                            readOnly: true,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                        labelText="Процент рейк (до 100)"
                                        id="percent_rake"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            type: "number",
                                            value: this.state.percent_rake,
                                            readOnly: true
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}></GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <div style={this.state.unlimited_entries ? { display: "none" } : {}}>
                                        <CustomInput
                                            labelText="Максимален брой входове от един потребител"
                                            id="max_entries_from_single_users"
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                type: "number",
                                                value: this.state.max_entries_from_single_users,
                                                readOnly: true
                                            }}
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.grid} >
                                    <CustomCheckboxLabel
                                        isChecked={this.state.unlimited_entries}
                                        labelName={"Без ограничение на входове от един потребител"}
                                    />
                                </GridItem>
                            </GridContainer>

                        </CardBody>
                    </Scrollbars>
                </Card>
            </GridContainer>

        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + successColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    },
    badgeTitle: {
        marginRight: "10px"
    }
});

const mapStateToProps = state => {

    return {
        tournament: state.championshipGames.tournament,
    }
};




export default connect(mapStateToProps, null)(withStyles(styles)(ViewSubGameForm));
