import {
    GET_ALL_ROUNDS,
    DELETE_ROUND,
    ADD_ROUND,
    EDIT_ROUND,
    GET_ROUND_STATUSES,
    GET_SETTINGS
} from 'Pages/Rounds/actions/actionTypes';

const initialState = {
    rounds: [],
    statuses: [],
    settings: []
}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
                settings: action.data
            }
        case GET_ALL_ROUNDS:
            return {
                ...state,
                rounds: action.rounds
            }
        case DELETE_ROUND:
            return {
                ...state,
                rounds: state.rounds.filter(r => r.id !== action.roundId)
            }
        case ADD_ROUND:
            return {
                ...state,
                rounds: state.rounds.concat(action.newRound)
            }
        case EDIT_ROUND:
            let arr = state.rounds.map((item, index) => {
                if (item.id === action.round.id) {
                    return action.round;
                }
                return item;
            });
            return {
                ...state,
                rounds: arr
            }
        case GET_ROUND_STATUSES:
            return {
                ...state,
                statuses: action.statuses
            }

        default:
            return state;
    }
}