import axios from 'axios';
import { BASE_URL } from "./Constants.js"
import { showNotification } from 'Toasts/actions/toasts';
import { store } from '../index';
import { showSpinner, hideSpinner } from 'Spinner/actions/spinner';


const instance = axios.create({
    baseURL: BASE_URL
});
instance.defaults.headers.common.Authorization = localStorage.getItem("token")
//instance.defaults.headers.common['Authorization'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE1Mzc5NDExNzYsImp0aSI6IlFtTHRiV3RtTHRnMUV3PT0iLCJpc3MiOiJLcmFzaSBTZXJ2ZXIiLCJleHAiOjE1MzgwMjc1NzYsImRhdGEiOnsidXNlcklkIjoxfX0.qeoOCISJ776yDK7IOxkqQaBvwL_FvMEfl2sIzBGQ8-gEoW1RrtSLRHjtoZG4BFEyAiDFa0M61uV9dsDyY7JsDA";


function onUploadProgress(ev) {
    console.log(ev);
}

instance.interceptors.request.use((config) => {

    if (!config.url.includes("unseen")) {
        store.dispatch(showSpinner());
    }
    return {
        ...config,
        onUploadProgress: config.onUploadProgress || onUploadProgress,

    }
}, error => {
    return Promise.reject(error)
})


instance.interceptors.response.use(function (response) {

    if (!response.config.url.includes("unseen")) {
        store.dispatch(hideSpinner());
    }


    if (response.data.message) {
        store.dispatch(showNotification({
            type: "success",
            msg: response.data.message
        }
        ));
    }

    return response;
}, function (error) {
    store.dispatch(hideSpinner());


    if (error && error.response && error.response.data) {
        store.dispatch(showNotification(
            {
                type: "error",
                msg: error.response.data.message
            }

        ));

        if (error.response.data.status === 401) {
            localStorage.clear();
            window.location.pathname = '/';
        }
    }


    return Promise.reject(error.response);
});

export default instance;