import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getRegisteredUsers = (data) => {
    return dispatch => {
        axios.get(`/stats/users/singUps?date_from=${data.date_from}&date_to=${data.date_to}&page=${data.page}`)
            .then(res => {
                dispatch(setRegisteredUsers(res.data.data));
            });
    }
}

export const setRegisteredUsers = data => {
    return {
        type: actionTypes.GET_REGISTERED_USERS_FOR_PERIOD,
        data
    }
}