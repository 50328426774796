import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import { getMostActiveUsersForPeriod } from '../actions/mostActiveUsers';
import { connect } from 'react-redux';
import { darkBlueColor, getBadgeColorForRoundStatus, convertDate } from 'utils/Constants'
import moment from 'moment'

import OutlinedPicker from "UI/OutlinedPicker/OutlinedPicker.js";
import Pagination from "UI/Pagination/Pagination";
import Button from "vendor/components/CustomButtons/Button.jsx";

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class MostActiveUsersForPeriod extends React.Component {

    state = {
        lastPage: 1,
        page: 1,
        date_from : moment(new Date()).format("YYYY-MM-DD"),
        date_to : moment(new Date()).format("YYYY-MM-DD"),
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.mostActiveUsers !== this.props.mostActiveUsers) {
            this.setState({
                page: nextProps.mostActiveUsers.current_page,
                lastPage: nextProps.mostActiveUsers.last_page,
            })
        }
    }

    componentWillMount() {
        this.props.getMostActiveUsersForPeriod(this.state);
    }

    onChangePage = (page) => {

        let data = { ...this.state };
        data.page = page;
        this.props.getMostActiveUsersForPeriod(data);
    }


    dateChangedHandler = (date, name) => {
        this.setState({ [name]: date.format("YYYY-MM-DD") });
    }

    filterHandler = () => {
        let data = {
            ...this.state,
            page: 1,
        };

        this.props.getMostActiveUsersForPeriod(data);
    }

    clearFilters = () => {
        let data = {
            lastPage: 1,
            page: 1,
            date_from: null,
            date_to: null
        };

        this.setState({
            ...data
        });


    }

    render() {
        const { classes } = this.props;
        const total = this.props.mostActiveUsers.total ? this.props.mostActiveUsers.total : ""
        const average = this.props.mostActiveUsers.total_avg_entries ? this.props.mostActiveUsers.total_avg_entries : ""
        let mappedUsers = [];

        if (this.props.mostActiveUsers && this.props.mostActiveUsers.data && this.props.mostActiveUsers.data.length > 0) {
            mappedUsers = this.props.mostActiveUsers.data.map(t => {

                return {
                    tableData: [t.first_name , t.last_name, t.email, t.total_entries],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>{`Брой потребители: ${total} , Честота на посещенията: ${average}`} </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>

                                    <GridItem xs={12} sm={12} md={6}>
                                    <OutlinedPicker
                                        labelName="От Дата"
                                        emptyLabel=" "
                                        onChange={(date) => { this.dateChangedHandler(date, "date_from") }}
                                        date={this.state.date_from}
                                        fullWidth={true}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                    <OutlinedPicker
                                        labelName="До Дата"
                                        emptyLabel=" "
                                        onChange={(date) => { this.dateChangedHandler(date, "date_to") }}
                                        date={this.state.date_to}
                                        fullWidth={true} />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.filterHandler}
                                            color="success">
                                            Филтрирай
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.clearFilters}
                                            color="danger">
                                            Изчисти филтрите
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Име", "Фамилия", "Имейл", "Общ брой посещения"]}
                                    tableData={mappedUsers}

                                />
                            </CardBody>
                            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.onChangePage}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        mostActiveUsers: state.mostActiveUsers.mostActiveUsers,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getMostActiveUsersForPeriod: (data) => dispatch(getMostActiveUsersForPeriod(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MostActiveUsersForPeriod));
