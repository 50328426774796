import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import { getAllChampionshipGames, deleteTournament } from 'Pages/ChampionshipGames/actions/championshipGames.js';
import { connect } from 'react-redux';
import { darkBlueColor, convertDateWithTime } from 'utils/Constants'
import Button from "vendor/components/CustomButtons/Button.jsx";
import List from "@material-ui/icons/List";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"
import AddButton from "UI/CustomButtons/AddButton/AddButton"
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'

const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    }
};

class ChampionshipGames extends React.Component {

    state = {
        confirmModalOpen: false,
        addModalOpen: false,
        editModalOpen: false,
        gameData: null,
        selectedId: null,
        tempGames: []
    }

    componentDidMount() {
        console.log("mount")
        this.props.getAllChampionshipGames();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.championshipGames != nextProps.championshipGames) {
            this.setState({
                tempGames: nextProps.championshipGames.filter(t => new Date(t.start_time) < new Date() && new Date(t.end_time) > new Date())
            })
        }
    }


    openConfirmDeleteHandler = (id) => {

        this.setState({
            confirmModalOpen: true,
            confirmMessage: "Сигурни ли сте че искате да изтриете играта?",
            deleteId: id
        })
    }

    editHandler = (id) => {
        this.props.history.push("/editChampionshipGame/" + id)
    }

    deleteHandler = (id) => {
        this.setState({ confirmModalOpen: false });
        this.props.deleteTournament(id);
    }

    addHandler = (id) => {
        this.props.history.push("/addChampionshipGame");
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false });
    }

    viewHandler = id => {
        this.props.history.push("/viewChampionshipGame/" + id)
    }

    closeViewHandlerHandler = (id) => {
        this.setState({ viewModalOpen: false });
    }

    setGamesPast = () => {
        this.setState({
            tempGames: this.props.championshipGames.filter(t => new Date(t.end_time) <= new Date())
        })
    }
    
    setGamesPresent = () => {
        this.setState({
            tempGames: this.props.championshipGames.filter(t => new Date(t.start_time) < new Date() && new Date(t.end_time) > new Date())
        })
    }
    
    setGamesFuture = () => {
        this.setState({
            tempGames: this.props.championshipGames.filter(t => new Date(t.start_time) > new Date())
        })
    }


    render() {
        const { classes } = this.props;
        let mappedGames = [];
        console.log(this.state.tempGames);
        if (this.props.championshipGames.length > 0) {
            mappedGames = this.state.tempGames.map(t => {
                
                const startTime = convertDateWithTime(t.start_time);
                const endTime = convertDateWithTime(t.end_time);
                const registerStart = convertDateWithTime(t.register_opened_start_time);
                const registerEnd = convertDateWithTime(t.register_opened_end_time);
               
                const view = (<TooltipButton labelName="Преглед" onClick={() => this.viewHandler(t.id)}> <List /></TooltipButton>);
                const edit = (<TooltipButton labelName="Редактирай" onClick={() => this.editHandler(t.id)}> <Edit /></TooltipButton>);
                const close = (<TooltipButton labelName="Изтрий" onClick={() => this.openConfirmDeleteHandler(t.id)}> <Close /></TooltipButton>);
                return {
                    tableData: [t.name, startTime, endTime, registerStart, registerEnd, view, edit, close],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={() => this.deleteHandler(this.state.deleteId)} />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Турнири: </h4>
                                <Button onClick={() => this.setGamesPast()} color="success"> Минали</Button>
                                <Button onClick={() => this.setGamesPresent()} color="success">Текущи</Button>
                                <Button onClick={() => this.setGamesFuture()} color="success"> Предстоящи</Button>
                                <AddButton labelName="Добави игра" onClick={this.addHandler} />
                            </CardHeader>
                            <CardBody>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Име", "Начало", "Край", "Начало регистрация", "Край регистрация", "   ", "   ", "   "]}
                                    tableData={mappedGames}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        championshipGames: state.championshipGames.championshipGames,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllChampionshipGames: () => dispatch(getAllChampionshipGames()),
        deleteTournament: id => dispatch(deleteTournament(id)),
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChampionshipGames));
