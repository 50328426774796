import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getPrivateLeagues = (data) => {
    return dispatch => {

        axios.post('/stats/privateLeagues', data)
            .then(res => {
                dispatch(setPrivateLeagues(res.data.data));
            });
    };
};

export const setPrivateLeagues = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_PRIVATE_LEAGUES,
        data: data
    };
};

export const clearFiltersPrivateLeagues = () => {
    return {
        type: actionTypes.CLEAR_FILTERS_PRIVATE_LEAGUES,
    }
}