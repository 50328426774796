import { GET_STATISTICS_WILDCARDS, CLEAR_FILTERS_WILDCARDS } from 'Pages/Statistics/Wildcards/actions/actionTypes';


const initialState = {
    wildcards: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_WILDCARDS:
            return {
                ...state,
                wildcards: action.data
            }

        case CLEAR_FILTERS_WILDCARDS:
            return {
                ...state,
                wildcards: []
            }

        default:
            return state;
    }
}