import { GET_STATISTICS_POPULAR_CAPTAINS, CLEAR_FILTERS_POPULAR_CAPTAINS } from 'Pages/Statistics/PopularCaptains/actions/actionTypes';


const initialState = {
    popularCaptains: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_POPULAR_CAPTAINS:
            return {
                ...state,
                popularCaptains: action.data
            }

        case CLEAR_FILTERS_POPULAR_CAPTAINS:
            return {
                ...state,
                popularCaptains: []
            }

        default:
            return state;
    }
}