import { GET_ALL_GAMES, DELETE_GAME, ADD_GAME, EDIT_GAME, GET_GAME_STATUSES, GET_HOST_TEAM_PLAYERS, GET_GUEST_TEAM_PLAYERS } from 'Pages/Games/actions/actionTypes';
import { CLEAR_GAMES } from '../actions/actionTypes';


const initialState = {
    games: [],
    statuses: [],
    hostTeamPlayers: [],
    guestTeamPlayers: []
}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_GAME_STATUSES:
            return {
                ...state,
                statuses: action.statuses
            }
        case GET_ALL_GAMES:
            return {
                ...state,
                games: action.games
            }
        case DELETE_GAME:
            return {
                ...state,
                games: state.games.filter(g => g.id !== action.gameId)
            }
        case ADD_GAME:
            return {
                ...state,
                games: state.games.concat(action.newGame)
            }
        case EDIT_GAME:
            let arr = state.games.map((item, index) => {
                if (item.id === action.editedGame.id) {
                    return action.editedGame;
                }
                return item;
            });
            return {
                ...state,
                games: arr
            }
        case GET_HOST_TEAM_PLAYERS:
            return {
                ...state,
                hostTeamPlayers: action.players
            }
        case GET_GUEST_TEAM_PLAYERS:
            return {
                ...state,
                guestTeamPlayers: action.players
            }
            case CLEAR_GAMES: 
            return {
                ...state,
                games: []
            }

        default:
            return state;
    }
}