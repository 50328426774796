import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getAllChampionshipGames = () => {
    return dispatch => {

        axios.get('/tournaments')
            .then(res => {
                dispatch(setAllChampionshipGames(res.data.data));
            });
    };
};

export const setAllChampionshipGames = (data) => {
    return {
        type: actionTypes.GET_ALL_CHAMPIONSHIP_GAMES,
        data
    };
};

export const addTournament = data => {
    return dispatch => {
        axios.post('/tournaments', data)
            .then(res => {
                dispatch(setTournamentAdded(res.data.data));
            });
    }
}

export const setTournamentAdded = data => {
    return {
        type: actionTypes.ADD_TOURNAMENT,
        data
    }
}

export const getTournamentById = id => {
    return dispatch => {
        axios.get(`/tournaments/${id}`)
            .then(res => {
                dispatch(setTournamentById(res.data.data));
            });
    }
}

export const setTournamentById = data => {
    return {
        type: actionTypes.GET_TOURNAMENT_BY_ID,
        data
    }
}

export const editTournament = data => {
    return dispatch => {
        axios.put(`/tournaments`, data)
            .then(res => {
                dispatch(setTournamentEditted(res.data.data));
            });
    }
}

export const setTournamentEditted = data => {
    return {
        type: actionTypes.EDIT_TOURNAMENT,
        data
    }
}

export const deleteTournament = id => {
    return dispatch => {
        axios.delete(`/tournaments/${id}`)
            .then(res => {
                dispatch(setTournamentDeleted(id));
            });
    }
}

export const setTournamentDeleted = id => {
    return {
        type: actionTypes.DELETE_TOURNAMENT,
        id
    }
}

export const getAllMatchesForChampionship = (id, start_date, end_date) => {
    console.log(id);
    return dispatch => {
        var filter = `?start_date=${start_date}&end_date=${end_date}`
        if (id) {
            filter += `&tournament_id=${id}`
        }
        axios.get(`/championships/games${filter}`)
            .then(res => {
                dispatch(setMatchesForChampionship(res.data.data, id));
            })
    }
}

export const setMatchesForChampionship = (data, id) => {
    return {
        type: actionTypes.GET_ALL_MATCHES_FOR_CHAMPIONSHIP,
        data,
        id
    }
}

export const addSubgameToTournament = (data) => {
    return dispatch => {
        axios.post("/subTournaments", data)
            .then(res => {
                dispatch(setSubGame(res.data.data));
            })
    }
}

export const setSubGame = data => {
    return {
        type: actionTypes.ADD_SUBGAME_TO_TOURNAMENT,
        data
    }
}

export const deleteSubTournament = id => {
    return dispatch => {
        axios.delete("/subTournaments/" + id)
            .then(res => {
                dispatch(setSubTourmentDeleted(id));
            })
    }
}

export const setSubTourmentDeleted = id => {
    return {
        type: actionTypes.DELETE_SUBTOURNAMENT,
        id
    }
}

export const editSubGame = (data) => {
    return dispatch => {
        axios.put("/subTournaments", data)
            .then(res => {
                dispatch(setSubgameEditted(res.data.data));
            })
    }
}

export const setSubgameEditted = data => {
    return {
        type: actionTypes.EDIT_SUBGAME,
        data
    }
}

export const clearTournament = () => {
    return {
        type: actionTypes.CLEAR_TOURNAMENT
    }
}

export const endGame = (id) => {
    return dispatch => {
        axios.post("/palms/subTournament/profits", { subTournamentId: id })
            .then(res => {
                dispatch(setGameEnded(id));
            })
    }
}

export const setGameEnded = (id) => {
    return {
        type: actionTypes.END_GAME,
        id
    }
}