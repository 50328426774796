import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomSelect from "UI/CustomSelect/CustomSelect";
import CustomPicker from "UI/CustomPicker/CustomPicker";
import Badge from "vendor/components/Badge/Badge.jsx"
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { darkBlueColor, getBadgeColorForAdPositions, playoffPhases, positions } from "utils/Constants"
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import { addAd, getAdPositions } from "Pages/Advertisements/actions/advertisements.js";
import { connect } from 'react-redux';
import UploadAdImage from "UI/UploadAdImage/UploadAdImage"
import axios from "utils/axios";
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import CustomCheckboxLabel from "UI/CustomCheckboxLabel/CustomCheckboxLabel";


class AddAdvertisment extends React.Component {

    state = {
        position: {
            id: 0
        },
        url: "",
        image: null,
        is_active: false
    }

    componentDidMount() {
        this.props.getAdPositions();
    }

    handleChangeInput = (name) => event => {

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };



    statusChangeHandler = (id) => {
        var selectedStatus = this.props.positions.find((t) => {
            return t.id === id;
        });


        this.setState({ position: selectedStatus });
    }

    imageChangedHandler = (event) => {
        if (!event.target.files[0]) {
            return
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var image = event.target.result;
            image = image.slice(image.indexOf(',') + 1);
            axios.post('/ads/image', { base64: image }).then((response) => {

                this.setState({ image: response.data.data });
            });
        }.bind(this);
    }

    activeChangeHanfdler = (event) => {
        this.setState({ is_active: event.target.checked })
    }


    addHandler = () => {
        this.props.onClose()

        let data = { ...this.state };
        this.props.addAd(data);
    }

    render() {
        const { classes } = this.props;

        //gameStatuses
        let mappedStatuses = [];

        if (this.props.positions && this.props.positions.length > 0) {

            mappedStatuses = this.props.positions.map(t => {
                return {
                    valueData: [
                        <Badge
                            key={t.id}
                            color={getBadgeColorForAdPositions(t.id)}>
                            {t.name}</Badge>
                    ],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="success" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Добави реклама</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <UploadAdImage
                                        description="Качи лого на рекламата"
                                        image={this.state.image}
                                        onChange={this.imageChangedHandler}
                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <OutlinedSelect
                                        labelName="Позиция"
                                        selectData={mappedStatuses}
                                        value={this.state.position.id}
                                        onChange={this.statusChangeHandler} />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12}>
                                    <OutlinedInput
                                        fullWidth={true}
                                        labelName="Линк на рекламата"
                                        value={this.state.url}
                                        onChange={this.handleChangeInput("url")} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomCheckboxLabel
                                        labelName={this.state.is_active ? "Активна" : "Неактивна"}
                                        isChecked={this.state.is_active}
                                        onChange={this.activeChangeHanfdler} />
                                </GridItem>


                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button
                                onClick={this.addHandler}
                                color="success">
                                Добави
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = {
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    }
};

const mapStateToProps = state => {

    return {
        positions: state.advertisements.positions,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addAd: (data) => dispatch(addAd(data)),
        getAdPositions: () => dispatch(getAdPositions())

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddAdvertisment));
