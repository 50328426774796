import { GET_STATISTICS_MOST_BOUGHT_PLAYERS, CLEAR_FILTERS_MOST_BOUGHT_PLAYERS } from 'Pages/Statistics/MostBoughtPlayers/actions/actionTypes';


const initialState = {
    mostBoughtPlayers: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_MOST_BOUGHT_PLAYERS:
            return {
                ...state,
                mostBoughtPlayers: action.data
            }

        case CLEAR_FILTERS_MOST_BOUGHT_PLAYERS:
            return {
                ...state,
                mostBoughtPlayers: []
            }

        default:
            return state;
    }
}