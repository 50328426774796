import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Select from '@material-ui/core/Select';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: "transperant",
    },
    formControl: {
        minWidth: 200,
        margin: theme.spacing.unit,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        alignItems: "flex-start"
    },
    chip: {
        margin: theme.spacing.unit / 4,

    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class MultipleSelect extends React.Component {
    state = {
        name: [

        ],
        isSelectOpened: false
    };


    handleChange = event => {
        this.props.onChange(event.target.value)
        this.setState({ name: event.target.value });
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ name: nextProps.value });
    }

    render() {
        const { classes } = this.props;
        let menuItems = this.props.selectData.map(item => {
            return <MenuItem key={item.id} value={item.id}> {item.valueData}</MenuItem>;
        });


        return (
            <div className={classes.root}>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple-chip">{this.props.labelName}</InputLabel>
                    <Select
                        multiple
                        value={this.props.value}
                        onChange={this.handleChange}
                        input={<Input id="select-multiple-chip" />}
                        selected={this.props.value}
                        renderValue={selected => (
                            <div className={classes.chips}>
                                {selected.map(value => (
                                    // <div> {selected} </div>
                                    this.props.selectData.find((t) => {
                                        return t.id === value;
                                    }) ? this.props.selectData.find((t) => {
                                        return t.id === value;
                                    }).valueData : null


                                    // <Chip label={value} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {menuItems}
                    </Select>
                </FormControl>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(MultipleSelect);
