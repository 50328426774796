import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getUsersEmails = () => {
    return dispatch => {

        axios.get('/notifications/emails')
            .then(res => {
                dispatch(setUsersEmails(res.data.data));
            });
    };
};

export const setUsersEmails = (data) => {
    return {
        type: actionTypes.GET_USERS_EMAILS,
        data: data
    };
};

export const sendGlobalNotification = (data) => {
    return dispatch => {

        axios.post('/notifications/global', data)
            .then(res => {
                //dispatch(setUsersEmails(res.data.data));
            });
    };
};

export const sendUserNotification = (data) => {
    return dispatch => {

        axios.post('/notifications/user', data)
            .then(res => {
                //dispatch(setUsersEmails(res.data.data));
            });
    };
};

// export const setUsersEmails = (data) => {
//     return {
//         type: actionTypes.GET_USERS_MAILS,
//         data: data
//     };
// };