import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorModal from 'Modals/ErrorModal/ErrorModal'
import SuccessModal from 'Modals/SuccessModal/SuccessModal'

class Toast extends Component {
    generateNotification(toast) {
        switch (toast.type.toUpperCase()) {
            case "SUCCESS":
                return <SuccessModal show={true}
                    text={toast.msg}>

                </SuccessModal>

            case "ERROR":
                return <ErrorModal show={true}
                    text={toast.msg}>
                </ErrorModal>

            default:
                return null
        }
    }
    componentWillReceiveProps(newProps) {


    }
    render() {
        let toast = <div />;
        Object.keys(this.props.toasts).map((notificationId) => {
            toast = this.generateNotification(this.props.toasts[notificationId]);
            return this.props.toasts[notificationId];
        });



        return (
            <div>
                {toast}
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        toasts: state.toasts,
    }
};

export default connect(mapStateToProps)(Toast);
