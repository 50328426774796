import { GET_ALL_ADS, EDIT_AD, DELETE_AD, ADD_AD, GET_AD_POSITIONS } from 'Pages/Advertisements/actions/actionTypes';

const initialState = {
    ads: [],
    positions: []
}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_AD_POSITIONS:
            return {
                ...state,
                positions: action.data
            }
        case GET_ALL_ADS:
            return {
                ...state,
                ads: action.data
            }

        case EDIT_AD: {
            let arr = state.ads.map((item, index) => {
                if (item.id === action.data.id) {
                    return action.data;
                }
                return item;
            });
            return {
                ...state,
                ads: arr
            }
        }
        case DELETE_AD:
            return {
                ...state,
                ads: state.ads.filter(g => g.id !== action.data.id)
            }

        case ADD_AD:
            return {
                ...state,
                ads: state.ads.concat(action.data)
            }

        default:
            return state;
    }
}