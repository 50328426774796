import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getMostActiveUsersForPeriod = (data) => {
    return dispatch => {
        axios.get(`/stats/users/mostActiveUsers?date_from=${data.date_from}&date_to=${data.date_to}&page=${data.page}`)
            .then(res => {
                dispatch(setMostActiveUsersForPeriod(res.data.data));
            });
    }
}

export const setMostActiveUsersForPeriod = data => {
    return {
        type: actionTypes.GET_MOST_ACTIVE_USERS_FOR_PERIOD,
        data
    }
}