import { GET_ACTIVE_USERS_FOR_PERIOD } from '../actions/actionTypes';


const initialState = {
    activeUsers: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_ACTIVE_USERS_FOR_PERIOD:
            return {
                ...state,
                activeUsers: action.data
            }
        default:
            return state;
    }
}