export const GET_ALL_CHAMPIONSHIP_GAMES = "GET_ALL_CHAMPIONSHIP_GAMES";
export const ADD_TOURNAMENT = "ADD_TOURNAMENT";
export const EDIT_TOURNAMENT = "EDIT_TOURNAMENT";
export const DELETE_TOURNAMENT = "DELETE_TOURNAMENT";
export const GET_TOURNAMENT_BY_ID = "GET_TOURNAMENT_BY_ID";
export const GET_ALL_MATCHES_FOR_CHAMPIONSHIP = "GET_ALL_MATCHES_FOR_CHAMPIONSHIP";
export const ADD_SUBGAME_TO_TOURNAMENT = "ADD_SUBGAME_TO_TOURNAMENT";
export const DELETE_SUBTOURNAMENT = "DELETE_SUBTOURNAMENT";
export const EDIT_SUBGAME = "EDIT_SUBGAME";
export const CLEAR_TOURNAMENT = "CLEAR_TOURNAMENT";
export const END_GAME = "END_GAME";