import { GET_STATISTICS_PRIVATE_LEAGUES, CLEAR_FILTERS_PRIVATE_LEAGUES } from 'Pages/Statistics/PrivateLeagues/actions/actionTypes';


const initialState = {
    privateLeagues: [],

}

export default (state = initialState, action) => {

    switch (action.type) {
        case GET_STATISTICS_PRIVATE_LEAGUES:
            return {
                ...state,
                privateLeagues: action.data
            }

        case CLEAR_FILTERS_PRIVATE_LEAGUES:
            return {
                ...state,
                privateLeagues: []
            }

        default:
            return state;
    }
}