import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getBoughtChips = (data) => {
    return dispatch => {

        axios.post('/stats/boughtChips', data)
            .then(res => {
                dispatch(setBoughtChips(res.data.data));
            });
    };
};

export const setBoughtChips = (data) => {
    return {
        type: actionTypes.GET_STATISTICS_BOUGHT_CHIPS,
        data: data
    };
};

export const clearFiltersBoughtChips = () => {
    return {
        type: actionTypes.CLEAR_FILTERS_BOUGHT_CHIPS,
    }
}