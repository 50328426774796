import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

const styles = theme => ({
    paper: {
        textAlign: "center",
        position: "absolute",
        width: "50%",
        backgroundColor: "transperant",
        boxShadow: "none",
        padding: theme.spacing.unit * 4,
        transform: "translate3d(50%, 10%, 0)",
        borderRadius: "10px",
        "&:hover,&:focus": {
            outline: "none"
        },
        "@media (max-width: 576px)": {
            width: "85%",
            transform: "translate3d(4%, 0%, 0)",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "16px 32px"
        },

    }
});

class SimpleModal extends React.Component {
    state = {
        open: false
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {

        this.setState({ open: false });
        if (this.props.close)
            this.props.close();
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ open: nextProps.open });
    }


    render() {
        const { classes } = this.props;
        return (
            <Modal
                open={this.state.open}
                onClose={this.handleClose}
            >
                <div className={classes.paper}>
                    {this.props.children}
                </div>
            </Modal>
        );
    }
}

SimpleModal.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(SimpleModal);
