import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "vendor/components/CustomButtons/Button.jsx";
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Table from "vendor/components/Table/Table.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";

import { connect } from 'react-redux';
import { darkBlueColor, getBadgeColorForGameStatus, convertDateWithTime } from 'utils/Constants'
import CustomModal from "UI/CustomModal/CustomModal"
import DeleteGameStatsForm from "Pages/GameStats/screens/DeleteGameStatsForm"
import AddGameStatsForm from "Pages/GameStats/screens/AddGameStatsForm"
import AddButton from "UI/CustomButtons/AddButton/AddButton"
import RemoveButton from "UI/CustomButtons/RemoveButton/RemoveButton"
import CustomSelect from "UI/CustomSelect/CustomSelect"
import CustomCheckboxLabel from "UI/CustomCheckboxLabel/CustomCheckboxLabel";
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import ChipMultipleSelect from "UI/ChipMultipleSelect/ChipMultipleSelect"
import ImageIcon from "UI/ImageIcon/ImageIcon";
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"
import {
    getAllGameStatsTypes,
    getStatsForGame,
    startFirstHalf,
    endFirstHalf,
    startSecondHalf,
    endSecondHalf,
    saveGameLive
} from 'Pages/GameStats/actions/gameStats.js';
import { getGameStatuses } from "Pages/Games/actions/games.js"

const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    alignedRight: {
        display: "flex",
        justifyContent: "flex-end"
    },
    alignedLeft: {
        display: "flex",
        justifyContent: "flex-start"
    },
    statsCard: {
        marginTop: "0px !important"
    },
    statValue: {
        color: darkBlueColor,
        fontSize: "1.4em",
        fontWeight: "700",
        textAlign: "center"
    },
    teamCard: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly"
    },
    statContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexWrap: "wrap"
    },
};

class TableList extends React.Component {

    state = {
        confirmHandler: null,
        openConfirmModal: false,
        currentStatsToDelete: [],
        deleteStatModalOpen: false,
        modalOpen: false,
        currentStatToAdd: null,
        currentPlayersList: null,
        currentOtherTeamPlayersList: null,
        gamePlayers: [],
        gameStats: {
            status: {
                id: 0
            },
            host_score: 0,
            guest_score: 0,
            media_diema_sport: false,
            media_diema_sport_2: false,
            media_nova_sport: false,
            media_gong: false,
            gong_mvp_vote_link: "",
            host_team_titulars: [],
            guest_team_titulars: [],
            host_team: {
                players: []
            },
            guest_team: {
                players: []
            },
            gong_mvp: {
                id: 0
            },
            online_store_url: "",
            host_coeff: "",
            guest_coeff: ""

        },


    }

    componentWillMount() {
        this.props.getGameStatuses();
        this.props.getAllGameStatsTypes();
        this.props.getStatsForGame(this.props.match.params.id);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.gameStats !== this.props.gameStats) {

            let host_team_titulars = nextProps.gameStats.host_team_titulars.map(p => {
                return p.id;
            });
            let guest_team_titulars = nextProps.gameStats.guest_team_titulars.map(p => {
                return p.id;
            });
            console.log(nextProps.gameStats)
            this.setState({
                gameStats: {
                    ...nextProps.gameStats,
                    host_team_titulars: host_team_titulars,
                    guest_team_titulars: guest_team_titulars,

                },

                gamePlayers: nextProps.gameStats.host_team.players.concat(nextProps.gameStats.guest_team.players)
            })
        }
    }

    handleCheck = name => event => {
        this.setState({
            gameStats: {
                ...this.state.gameStats,
                [name]: event.target.checked
            }
        });
    };

    voteLinkChangeHandler = (event) => {
        this.setState({
            gameStats: {
                ...this.state.gameStats,
                gong_mvp_vote_link: event.target.value
            }
        });
    }

    startButton = () => {
        if (this.state.gameStats.status.id === 1) {
            this.props.startFirstHalf(this.state.gameStats.id)
        } else if (this.state.gameStats.status.id === 3) {
            this.props.startSecondHalf(this.state.gameStats.id)
        }
        this.setState({ openConfirmModal: false, confirmHandler: null });
    }

    stopButton = () => {
        if (this.state.gameStats.status.id === 2) {
            this.props.endFirstHalf(this.state.gameStats.id)
        } else if (this.state.gameStats.status.id === 4) {
            this.props.endSecondHalf(this.state.gameStats.id)
        }
        this.setState({ openConfirmModal: false, confirmHandler: null });
    }

    closeConfirmModalHandler = () => {
        this.setState({ openConfirmModal: false, confirmHandler: null });
    }

    startButtonConfirm = () => {
        if (this.state.gameStats.status.id === 1 || this.state.gameStats.status.id === 3) {
            this.setState({
                openConfirmModal: true,
                confirmMessage: this.state.gameStats.status.id === 1 ? "Сигурни ли сте че искате да стартирате ПЪРВОТО полувреме?" : "Сигурни ли сте че искате да стартирате ВТОРОТО полувреме?",
                confirmHandler: this.startButton
            });
        }
    }

    stopButtonConfirm = () => {
        if (this.state.gameStats.status.id === 2 || this.state.gameStats.status.id === 4) {
            this.setState({
                openConfirmModal: true,
                confirmMessage: this.state.gameStats.status.id === 2 ? "Сигурни ли сте че искате да завършите ПЪРВОТО полувреме?" : "Сигурни ли сте че искате да завършите ВТОРОТО полувреме?",
                confirmHandler: this.stopButton
            });
        }

    }

    hostTeamPlayersOnChange = (ids) => {

        this.setState({
            gameStats: {
                ...this.state.gameStats,
                host_team_titulars: ids
            }
        });
    }

    guestTeamPlayersOnChange = (ids) => {

        this.setState({
            gameStats: {
                ...this.state.gameStats,
                guest_team_titulars: ids
            }
        });
    }

    gongMVPChangeHandler = (id) => {

        var selected = this.state.gamePlayers.find((t) => {
            return t.id === id;
        });

        this.setState({
            gameStats: {
                ...this.state.gameStats,
                gong_mvp: selected
            }
        });
        this.setState({ gong_mvp: { id: selected } });
    }

    saveChangesConfirm = () => {
        let host_team_titulars = this.state.gameStats.host_team_titulars.map(id => {
            return {
                id: id
            };
        });
        let guest_team_titulars = this.state.gameStats.guest_team_titulars.map(id => {
            return {
                id: id
            };
        });


        var data = {
            id: this.state.gameStats.id,
            gong_mvp: this.state.gameStats.gong_mvp,
            gong_mvp_vote_link: this.state.gameStats.gong_mvp_vote_link,
            media_diema_sport: this.state.gameStats.media_diema_sport,
            media_nova_sport: this.state.gameStats.media_nova_sport,
            media_gong: this.state.gameStats.media_gong,
            media_diema_sport_2: this.state.gameStats.media_diema_sport_2,
            host_team_titulars: host_team_titulars,
            guest_team_titulars: guest_team_titulars,
            host_coeff: this.state.gameStats.host_coeff,
            guest_coeff: this.state.gameStats.guest_coeff,
        }
        this.props.saveGameLive(data);
        this.closeConfirmModalHandler();
    }


    saveGameChanges = () => {

        this.setState({
            openConfirmModal: true,
            confirmMessage: "Сигурни ли сте, че искате да запазите промените?",
            confirmHandler: this.saveChangesConfirm
        });

    }

    removeStatHostTeamHandler = (id) => {
        let arr = this.props.gameStats.host_team.stats.filter(stat => stat.stat_type.id === id);
        this.setState({
            isHost: true,
            modalOpen: false,
            deleteStatModalOpen: true,
            currentStatsToDelete: arr
            // currentStatToAdd: selected,
            //currentPlayersList: this.props.gameStats.host_team.players
        });
    }

    addStatHostTeamHandler = (id) => {
        var selected = this.props.gameStatsTypes.find((t) => {
            return t.id === id;
        });
        this.setState({
            isHost: true,
            modalOpen: true,
            deleteStatModalOpen: false,
            currentStatToAdd: selected,
            currentPlayersList: this.props.gameStats.host_team.players,
            currentOtherTeamPlayersList: this.props.gameStats.guest_team.players
        });
    }

    removeStatGuestTeamHandler = (id) => {
        let arr = this.props.gameStats.guest_team.stats.filter(stat => stat.stat_type.id === id);
        this.setState({
            isHost: false,
            modalOpen: false,
            deleteStatModalOpen: true,
            currentStatsToDelete: arr
            // currentStatToAdd: selected,
            //currentPlayersList: this.props.gameStats.host_team.players
        });
        // 
        // var lastItem = arr.pop();
        // this.props.deleteStatForGame(this.state.gameStats.id, lastItem.id, true)
    }

    addStatGuestTeamHandler = (id) => {
        var selected = this.props.gameStatsTypes.find((t) => {
            return t.id === id;
        });
        this.setState({
            isHost: false,
            modalOpen: true,
            deleteStatModalOpen: false,
            currentStatToAdd: selected,
            currentPlayersList: this.props.gameStats.guest_team.players,
            currentOtherTeamPlayersList: this.props.gameStats.host_team.players
        });
    }

    closeModal = () => {
        this.setState({
            modalOpen: false,
            deleteStatModalOpen: false
        });
    }

    closeDeleteStatModal = () => {
        this.setState({
            modalOpen: false,
            deleteStatModalOpen: false
        });
    }

    render() {
        const { classes } = this.props;
        let mappedStats = [];
        let score = " "
        let hostTeam = " "
        let guestTeam = " ";
        let mappedStatuses = [];


        if ('gameStats' in this.props && Object.keys(this.props.gameStats).length > 0) {


            score =
                <div className={classes.teamCard}>

                    {this.state.gameStats.host_score} :  {this.state.gameStats.guest_score}
                </div>;

            hostTeam =
                <div className={classes.teamCard}>
                    <ImageIcon image={this.props.gameStats.host_team.logo} />
                    {this.props.gameStats.host_team.name}
                </div>;
            guestTeam =
                <div className={classes.teamCard}>
                    <ImageIcon image={this.props.gameStats.guest_team.logo} />
                    {this.props.gameStats.guest_team.name}
                </div>;

            let hostTeamStatsMap = this.props.gameStats.host_team.stats.reduce(function (prev, cur) {
                prev[cur.stat_type.id] = (prev[cur.stat_type.id] || 0) + 1;
                return prev;
            }, {});

            let guestTeamStatsMap = this.props.gameStats.guest_team.stats.reduce(function (prev, cur) {
                prev[cur.stat_type.id] = (prev[cur.stat_type.id] || 0) + 1;
                return prev;
            }, {});

            if (this.props.gameStatsTypes.length > 0) {
                mappedStats = this.props.gameStatsTypes.map(t => {
                    const title = <CardHeader color="warning" className={[classes.card, classes.statsCard].join(" ")}>
                        <h4 className={classes.cardTitle}>{t.name} </h4>
                    </CardHeader>
                    return [
                        <div className={classes.alignedRight} onClick={() => this.addStatHostTeamHandler(t.id)} >
                            <AddButton labelName="Добави" />
                        </div>,
                        <div className={classes.statValue}>{hostTeamStatsMap[t.id] ? hostTeamStatsMap[t.id] : 0}</div>,
                        <div className={classes.alignedLeft} onClick={() => this.removeStatHostTeamHandler(t.id)}><RemoveButton /></div>,
                        title,
                        <div className={classes.alignedRight} onClick={(id) => this.addStatGuestTeamHandler(t.id)}>
                            <AddButton labelName="Добави" />
                        </div>,
                        <div className={classes.statValue}>{guestTeamStatsMap[t.id] ? guestTeamStatsMap[t.id] : 0}</div>,
                        <div className={classes.alignedLeft} onClick={(id) => this.removeStatGuestTeamHandler(t.id)}><RemoveButton /></div>,
                    ];
                });
            }
        }



        if (this.props.gameStatuses && this.props.gameStatuses.length > 0) {
            mappedStatuses = this.props.gameStatuses.map(t => {
                return {
                    valueData: [
                        <Badge
                            key={t.id}
                            color={getBadgeColorForGameStatus(t.id)}>
                            {t.name}</Badge>
                    ],
                    id: t.id
                };
            });
        }


        let mappedHostTeamPlayers = [{
            valueData: [],
            id: 0
        }];
        if (this.state.gameStats.host_team.players.length > 0) {
            mappedHostTeamPlayers = this.state.gameStats.host_team.players.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.image} />
                            {t.first_name + " " + t.last_name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedGuestTeamPlayers = [{
            valueData: [],
            id: 0
        }];
        if (this.state.gameStats.guest_team.players.length > 0) {
            mappedGuestTeamPlayers = this.state.gameStats.guest_team.players.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.image} />
                            {t.first_name + " " + t.last_name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        let mappedAllPlayers = [{
            valueData: [],
            id: 0
        }];
        if (this.state.gamePlayers.length > 0) {
            mappedAllPlayers = this.state.gamePlayers.map(t => {
                return {
                    valueData: [
                        <div key={t.id} className={classes.selectItem}>
                            <ImageIcon image={t.image} />
                            {t.first_name + " " + t.last_name}
                        </div>
                    ],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <ConfirmModal
                    open={this.state.openConfirmModal}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={this.state.confirmHandler} />
                <CustomModal open={this.state.modalOpen} close={this.closeModal}>
                    <AddGameStatsForm
                        isHost={this.state.isHost}
                        onClose={this.closeModal}
                        gameId={this.props.match.params.id}
                        statType={this.state.currentStatToAdd}
                        players={this.state.currentPlayersList}
                        otherTeamPlayers={this.state.currentOtherTeamPlayersList} />
                </CustomModal>
                <CustomModal open={this.state.deleteStatModalOpen} close={this.closeDeleteStatModal}>
                    <DeleteGameStatsForm
                        isHost={this.state.isHost}
                        onClose={this.closeDeleteStatModal}
                        gameId={this.props.match.params.id}
                        stats={this.state.currentStatsToDelete}
                    />
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Статистики: </h4>
                            </CardHeader>
                            <CardBody>
                                <Card>
                                    <div className={classes.statContainer}>
                                        <Button
                                            round
                                            onClick={this.startButtonConfirm}
                                            color="success">
                                            Старт
                                         </Button>
                                        <Button
                                            round
                                            onClick={this.stopButtonConfirm}
                                            color="danger">
                                            Стоп
                                         </Button>
                                        <CustomSelect
                                            labelName="Статус"
                                            selectData={mappedStatuses}
                                            value={this.state.gameStats.status.id}
                                            disabled={true}
                                            onChange={this.statusChangeHandler}
                                        />
                                        <CustomInput
                                            labelText="Начало"

                                            formControlProps={{}}
                                            inputProps={{
                                                disabled: true,
                                                value: convertDateWithTime(this.state.start_date),
                                                //onChange: (event) => this.lastNameChangeHandler(event)
                                            }}
                                        />

                                        <div> </div>
                                    </div>
                                    <div className={classes.statContainer}>
                                        <CustomCheckboxLabel
                                            labelName="Диема Спорт"
                                            isChecked={this.state.gameStats.media_diema_sport}
                                            onChange={this.handleCheck("media_diema_sport")} />
                                        <CustomCheckboxLabel
                                            labelName="Диема Спорт 2"
                                            isChecked={this.state.gameStats.media_diema_sport_2}
                                            onChange={this.handleCheck("media_diema_sport_2")} />
                                        <CustomCheckboxLabel
                                            labelName="Нова Спорт"
                                            isChecked={this.state.gameStats.media_nova_sport}
                                            onChange={this.handleCheck("media_nova_sport")} />
                                        <CustomCheckboxLabel
                                            labelName="Гонг"
                                            isChecked={this.state.gameStats.media_gong}
                                            onChange={this.handleCheck("media_gong")} />
                                        <CustomInput
                                            labelText="gong_vote_mvp_link"
                                            formControlProps={{}}
                                            inputProps={{
                                                value: this.state.gameStats.gong_mvp_vote_link ? this.state.gameStats.gong_mvp_vote_link : "",
                                                onChange: (event) => this.voteLinkChangeHandler(event)
                                            }}
                                        />
                                    </div>

                                    <div className={classes.statContainer}>

                                        <div>{`Коефициент домакин: ${this.state.gameStats.host_coeff}`}</div>
                                        <div>{`Линк на закупуване на билети: ${this.state.gameStats.online_store_url}`}</div>
                                        <div>{`Коефициент гост: ${this.state.gameStats.guest_coeff}`}</div>
                                        {/* <OutlinedInput
                                            disabled={true}
                                            type={"number"}
                                            labelName="Коефициент домакин"
                                            value={}
                                            onChange={() => {}} /> */}
                                        {/* <OutlinedInput
                                        disabled={true}
                                            labelName="Коефициент гост"
                                            value={this.state.gameStats.guest_coeff}
                                            onChange={() => {}} />

                                        <OutlinedInput
                                        disabled={true}
                                            labelName="Линк на закупуване на билети"
                                            value={this.state.gameStats.online_store_url}
                                            onChange={() => {}} /> */}
                                    </div>

                                </Card>
                                <Table
                                    tableHeaderColor="gray"
                                    tableHead={[" ", hostTeam, " ", score, "  ", guestTeam, " "]}
                                    tableData={mappedStats}
                                    tableCellStyle="tableCellWithoutBorder"
                                />
                                <Card>
                                    <div className={classes.statContainer}>
                                        <ChipMultipleSelect
                                            labelName="Домакин Титуляри"
                                            value={this.state.gameStats.host_team_titulars}
                                            selectData={mappedHostTeamPlayers}
                                            onChange={this.hostTeamPlayersOnChange}
                                        />

                                        <CustomSelect
                                            labelName="Играч на мача"
                                            selectData={mappedAllPlayers}
                                            value={this.state.gameStats.gong_mvp ? this.state.gameStats.gong_mvp.id : 0}
                                            onChange={(event) => this.gongMVPChangeHandler(event)}

                                        />

                                        <ChipMultipleSelect
                                            labelName="Гост Титуляри"
                                            value={this.state.gameStats.guest_team_titulars}
                                            selectData={mappedGuestTeamPlayers}
                                            onChange={this.guestTeamPlayersOnChange}
                                        />
                                    </div>
                                    <div className={classes.statContainer}>
                                        <Button
                                            round
                                            onClick={this.saveGameChanges}
                                            color="success">
                                            Запази промените
                                         </Button>
                                    </div>
                                </Card>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        gameStatsTypes: state.gameStats.gameStatsTypes,
        gameStats: state.gameStats.gameStats,
        gameStatuses: state.games.statuses,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllGameStatsTypes: () => dispatch(getAllGameStatsTypes()),
        getStatsForGame: (id) => dispatch(getStatsForGame(id)),
        getGameStatuses: () => dispatch(getGameStatuses()),
        startFirstHalf: (id) => dispatch(startFirstHalf(id)),
        startSecondHalf: (id) => dispatch(startSecondHalf(id)),
        endFirstHalf: (id) => dispatch(endFirstHalf(id)),
        endSecondHalf: (id) => dispatch(endSecondHalf(id)),
        saveGameLive: (data) => dispatch(saveGameLive(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TableList));
