import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const getInviters = (page) => {
    return dispatch => {
        axios.get(`/stats/users/inviters?order=asc&page=${page}`)
            .then(res => {
                dispatch(setInviters(res.data.data));
            });
    };
};

export const setInviters = (data) => {
    return {
        type: actionTypes.GET_INVITERS,
        data
    }
}

export const getAllInviters = (page) => {
    return dispatch => {
        axios.get(`/stats/users/inviters?order=asc`)
            .then(res => {
                dispatch(setAllInviters(res.data.data.data));
            });
    };
};

export const setAllInviters = (data) => {
    return {
        type: actionTypes.GET_ALL_INVITERS,
        data
    }
}
