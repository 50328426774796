import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import Table from "UI/Table/Table.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import Badge from "vendor/components/Badge/Badge.jsx"
import Button from "vendor/components/CustomButtons/Button.jsx";

import { getSettings, getAllRounds, deleteRound, closeActiveRound, makeChanges, makeProfits, makeCopy } from 'Pages/Rounds/actions/rounds.js';
import { connect } from 'react-redux';
import { getBadgeColorForRoundStatus, darkBlueColor } from 'utils/Constants'

import CustomModal from "UI/CustomModal/CustomModal"
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"
import CustomCheckbox from "UI/CustomCheckbox/CustomCheckbox"
import AddRoundForm from "./AddRoundForm"
import EditRoundForm from "./EditRoundForm"
import AddButton from "UI/CustomButtons/AddButton/AddButton"
import { darkYellowColor } from "../../../utils/Constants";

const styles = {
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
};

class RoundsList extends React.Component {

    state = {
        addModalOpen: false,
        editModalOpen: false,
        confirmModalOpen: false,
        confirmModalChangeRound: false,
        confirmChanges: false

    }

    componentWillMount() {
        this.props.getSettings();
        this.props.getAllRounds(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.getAllRounds(this.props.match.params.id);
        }
    }

    openConfirmDeleteHandler = (id) => {

        this.setState({
            confirmModalOpen: true,
            confirmChanges: false,
            confirmModalOpenCloseActiveRound: false,
            confirmProfits: false,
            confirmMessage: "Сигурни ли сте че искате да изтриете кръга?",
            deleteId: id
        })
    }

    openConfirmCloseActiveRoundHandler = () => {

        this.setState({
            confirmModalOpen: false,
            confirmChanges: false,
            confirmModalOpenCloseActiveRound: true,
            confirmProfits: false,
            confirmCopy: false,
            confirmMessage: "Сигурни ли сте че искате да приключите кръга?",
        })
    }

    openConfirmChangesHandler = () => {

        this.setState({
            confirmModalOpen: false,
            confirmModalOpenCloseActiveRound: false,
            confirmChanges: true,
            confirmCopy: false,
            confirmProfits: false,
            confirmMessage: "Сигурни ли сте, че искате да извършите смяната на текущия кръг?",
        })
    }

    openConfirmCopyHandler = () => {
        this.setState({
            confirmModalOpen: false,
            confirmModalOpenCloseActiveRound: false,
            confirmCopy: true,
            confirmChanges: false,
            confirmProfits: false,
            confirmMessage: "Сигурни ли сте, че искате да копирате отборите за предстоящия кръг?",
        })
    }
    
    openConfirmPtofitsHandler = () => {

        this.setState({
            confirmModalOpen: false,
            confirmModalOpenCloseActiveRound: false,
            confirmChanges: false,
            confirmCopy: false,
            confirmProfits: true,
            confirmMessage: "Сигурни ли сте, че искате да разпределите печалбите от текущия кръг?",
        })
    }

    makeChanges = () => {
        this.setState({ confirmChanges: false });
        this.props.makeChanges();

        const timeOutInterval = 2 * 1000;
        this.notificationsIntervalId = setTimeout(() => {

            this.props.getSettings();

        }, timeOutInterval);
    }

    makeProfits = () => {
        this.setState({ confirmProfits: false });
        this.props.makeProfits();

        const timeOutInterval = 2 * 1000;
        this.notificationsIntervalId = setTimeout(() => {

            this.props.getSettings();

        }, timeOutInterval);

    }
    editHandler = (id) => {
        var selected = this.props.rounds.find((t) => {
            return t.id === id;
        });

        this.setState({
            addModalOpen: false,
            editModalOpen: true,
            round: selected
        });
    }

    deleteHandler = (id) => {
        this.setState({ confirmModalOpen: false });
        this.props.deleteRound(id);
    }

    addHandler = (id) => {
        this.setState({ addModalOpen: true, editModalOpen: false });
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false, confirmModalOpenCloseActiveRound: false, confirmChanges: false });
    }

    closeConfirmModalCloseActiveRoundHandler = (id) => {
        this.setState({ confirmModalOpen: false, confirmModalOpenCloseActiveRound: false, confirmChanges: false });
    }

    closeConfirmProfitsHandler = (id) => {
        this.setState({
            confirmModalOpen: false,
            confirmModalOpenCloseActiveRound: false,
            confirmChanges: false,
            confirmProfits: false
        });
    }

    closeConfirmCopyHandler = () => {
        this.setState({
            confirmModalOpen: false,
            confirmModalOpenCloseActiveRound: false,
            confirmChanges: false,
            confirmProfits: false,
            confirmCopy: false
        });
    }

    closeActiveRoundHandler = () => {
        this.props.closeActiveRound();
        this.closeConfirmModalCloseActiveRoundHandler();
    }

    addCloseModal = () => {
        this.setState({ addModalOpen: false });
    }

    editCloseModal = () => {
        this.setState({ editModalOpen: false });
    }

    closeActiveRound = () => {
        this.props.closeActiveRound();
        const timeOutInterval = 2 * 1000;
        this.notificationsIntervalId = setTimeout(() => {

            this.props.getSettings();

        }, timeOutInterval);
    }

    makeCopy = () => {
        this.setState({
            confirmModalOpen: false,
            confirmModalOpenCloseActiveRound: false,
            confirmChanges: false,
            confirmProfits: false,
            confirmCopy: false
        });
        this.props.makeCopy();
    }

    render() {
        const { classes } = this.props;
        let mappedTeams = [];

        if (this.props.rounds !== undefined && this.props.rounds.length > 0) {
            mappedTeams = this.props.rounds.map(t => {
                const status = <Badge color={getBadgeColorForRoundStatus(t.status.id)}>{t.status.name}</Badge>;
                const startDate = new Date(t.start_date).toLocaleDateString("en-US");
                const endDate = new Date(t.end_date).toLocaleDateString("en-US");
                const playoff = <CustomCheckbox isChecked={t.playoff} />
                return {
                    tableData: [t.name, t.number, startDate, endDate, status, playoff],
                    id: t.id
                };
            });

        }
        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={() => this.deleteHandler(this.state.deleteId)} />
                <ConfirmModal
                    open={this.state.confirmModalOpenCloseActiveRound}
                    close={this.closeConfirmModalCloseActiveRoundHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={this.closeActiveRoundHandler} />
                <ConfirmModal
                    open={this.state.confirmChanges}
                    close={this.closeConfirmModalCloseActiveRoundHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={this.makeChanges} />
                <ConfirmModal
                    open={this.state.confirmProfits}
                    close={this.closeConfirmProfitsHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={this.makeProfits} />
                    <ConfirmModal
                    open={this.state.confirmCopy}
                    close={this.closeConfirmCopyHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={this.makeCopy} />
                <CustomModal open={this.state.addModalOpen} close={this.addCloseModal}>
                    <AddRoundForm onClose={this.addCloseModal} championshipId={this.props.match.params.id} />
                </CustomModal>
                <CustomModal open={this.state.editModalOpen} close={this.editCloseModal}>
                    <EditRoundForm onClose={this.editCloseModal} round={this.state.round} />
                </CustomModal>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h3 style={{ color: darkYellowColor }}>{this.props.settings.display_status}</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Button
                            disabled={!this.props.settings.auto_swaps_available}
                            round
                            onClick={this.openConfirmChangesHandler}
                            color="success">
                            Извърши смени за текущия кръг
                            </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Button
                            round
                            onClick={this.openConfirmCopyHandler}
                            color="success">
                            Копирай отбори за предстоящ кръг
                            </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                        <Button
                            disabled={!this.props.settings.distribute_palms_profits_available}
                            round
                            onClick={this.openConfirmPtofitsHandler}
                            color="primary">
                            Разпредели печалбите за текущия кръг
                            </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button
                            disabled={!this.props.settings.end_current_round_available}
                            round
                            onClick={this.openConfirmCloseActiveRoundHandler}
                            color="danger">
                            Приключи текущия кръг
                            </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Кръгове: </h4>

                                <AddButton labelName="Добави кръг" onClick={() => this.addHandler()} />
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHeaderColor="gray"
                                    tableHead={["Име", "Номер", "Начало", "Край", "Статус", "Playoff", "   "]}
                                    tableData={mappedTeams}
                                    editHandler={this.editHandler}
                                    deleteHandler={this.openConfirmDeleteHandler}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {
        rounds: state.rounds.rounds,
        settings: state.rounds.settings
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAllRounds: (id) => dispatch(getAllRounds(id)),
        deleteRound: id => dispatch(deleteRound(id)),
        closeActiveRound: () => dispatch(closeActiveRound()),
        makeChanges: () => dispatch(makeChanges()),
        makeProfits: () => dispatch(makeProfits()),
        getSettings: () => dispatch(getSettings()),
        makeCopy: () => dispatch(makeCopy())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RoundsList));
