import {
    GET_ALL_GAME_STATS_TYPES,
    GET_STATS_FOR_GAME,
    ADD_STAT, DELETE_STAT,
    START_FIRST_HALF,
    END_FIRST_HALF,
    START_SECOND_HALF,
    END_SECOND_HALF,
    SAVE_GAME_LIVE,
    GET_GAME_SCORE
} from 'Pages/GameStats/actions/actionTypes';


const initialState = {
    gameStatsTypes: [],
    gameStats: []

}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_GAME_STATS_TYPES:
            return {
                ...state,
                gameStatsTypes: action.statsTypes
            }
        case GET_STATS_FOR_GAME:
            return {
                ...state,
                gameStats: action.gameStats
            }
        case ADD_STAT:
            let arr = []
            if (action.isHost) {
                arr = state.gameStats.host_team.stats;
                return {
                    ...state,
                    gameStats: {
                        ...state.gameStats,
                        host_team: {
                            ...state.gameStats.host_team,
                            stats: arr.concat(action.gameStat)
                        }
                    }
                }
            } else {
                arr = state.gameStats.guest_team.stats;
                return {
                    ...state,
                    gameStats: {
                        ...state.gameStats,
                        guest_team: {
                            ...state.gameStats.guest_team,
                            stats: arr.concat(action.gameStat)
                        }
                    }
                }
            }
        case DELETE_STAT:
            if (action.isHost) {
                arr = state.gameStats.host_team.stats.filter(g => g.id !== action.id)
                return {
                    ...state,
                    gameStats: {
                        ...state.gameStats,
                        host_team: {
                            ...state.gameStats.host_team,
                            stats: arr
                        }
                    }
                }
            } else {
                arr = state.gameStats.guest_team.stats.filter(g => g.id !== action.id)
                return {
                    ...state,
                    gameStats: {
                        ...state.gameStats,
                        guest_team: {
                            ...state.gameStats.guest_team,
                            stats: arr
                        }
                    }
                }
            }
        case START_FIRST_HALF:
            return {
                ...state,
                gameStats: {
                    ...state.gameStats,
                    status: action.newStatus
                }
            }
        case END_FIRST_HALF:
            return {
                ...state,
                gameStats: {
                    ...state.gameStats,
                    status: action.newStatus
                }
            }
        case START_SECOND_HALF:
            return {
                ...state,
                gameStats: {
                    ...state.gameStats,
                    status: action.newStatus
                }
            }
        case END_SECOND_HALF:
            return {
                ...state,
                gameStats: {
                    ...state.gameStats,
                    status: action.newStatus
                }
            }
        case SAVE_GAME_LIVE:
            return {
                ...state,
                gameStats: action.gameStats
            }
        case GET_GAME_SCORE:
            return {
                ...state,
                gameStats: {
                    ...state.gameStats,
                    host_score: action.gameScore.host_score,
                    guest_score: action.gameScore.guest_score
                }
            }
        default:
            return state;
    }
}