import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import { BASE_URL } from "utils/Constants.js";
import default_image from 'assets/img/default_image.png';

class UploadImage extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.pictureContainer}>
                <div className={classes.picture}>
                    <img src={this.props.image ? (BASE_URL + this.props.image.url) : (default_image)} className={classes.pictureSrc} alt="..." />
                    <input className={classes.input} type="file" onChange={(event) => this.props.onChange(event)} />
                </div>
                <div className={classes.description}>{this.props.description}</div>
            </div>
        );
    };

}

const styles = {

    pictureContainer: {
        position: "relative",
        cursor: "pointer",
        textAlign: "center"
    },
    picture: {
        width: "106px",
        height: "106px",
        backgroundColor: "#999",
        border: "4px solid #ccc",
        color: "#fff",
        borderRadius: "50%",
        margin: "5px auto",
        overflow: "hidden",
        transition: "all .2s",
        "@media (max-width: 576px)": {
            width: "5em",
            height: "5em",
        },

    },
    pictureSrc: {
        width: "100%"
    },
    description: {
        fontSize: ".8em",
        textTransform: "uppercase",
        fontWeight: "500"
    },
    input: {
        cursor: "pointer",
        display: "block",
        height: "100%",
        left: "0",
        opacity: "0!important",
        position: "absolute",
        top: "0",
        width: "100%"
    },
};




export default withStyles(styles)(UploadImage);
