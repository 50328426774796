import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Dashboard/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Dashboard/Grid/GridContainer.jsx";
import TableWithActionButtons from "UI/TableWithActionButtons/TableWithActionButtons.jsx";
import Card from "vendor/components/Dashboard/Card/Card.jsx";
import CardHeader from "vendor/components/Dashboard/Card/CardHeader.jsx";
import CardBody from "vendor/components/Dashboard/Card/CardBody.jsx";
import ImageIcon from 'UI/ImageIcon/ImageIcon'
import { filterPalmsTransactions } from 'Pages/PalmsTransactions/actions/palmsTransactions.js';

import { connect } from 'react-redux';
import Badge from "vendor/components/Badge/Badge.jsx"
import { getBadgeColorForGameStatus, darkBlueColor, getBadgeColorForRoundStatus, convertDateWithTime } from 'utils/Constants'
import moment from 'moment'
import Edit from "@material-ui/icons/Edit";
import CustomModal from "UI/CustomModal/CustomModal"
import TooltipButton from 'UI/CustomButtons/TooltipButton/TooltipButton'
import CustomSelect from "UI/CustomSelect/CustomSelect";
import CustomInput from "vendor/components/Dashboard/CustomInput/CustomInput.jsx";
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import OutlinedSelect from "UI/OutlinedSelect/OutlinedSelect";
import Email from "@material-ui/icons/Email";
import PersonOutline from "@material-ui/icons/PersonOutline";
import Location from "@material-ui/icons/LocationOn";
import Pagination from "UI/Pagination/Pagination";
import Button from "vendor/components/CustomButtons/Button.jsx";
import OutlinedPicker from "UI/OutlinedPicker/OutlinedPicker.js";
import Calendar from "@material-ui/icons/DateRange";


const styles = {

    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    badgeTitle: {
        marginRight: "10px"
    },
    inputIconsColor: {
        color: "lightgray"
    },
};

class PalmsTransactionsList extends React.Component {

    state = {
        lastPage: 1,
        page: 1,
        date_from: null,
        date_to: null

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.palmsTransactions !== this.props.palmsTransactions) {
            this.setState({
                page: nextProps.palmsTransactions.current_page,
                lastPage: nextProps.palmsTransactions.last_page,
            })
        }
    }

    componentWillMount() {
        this.props.filterPalmsTransactions(this.state);

    }

    onChangePage = (page) => {

        let data = { ...this.state };
        data.page = page;
        this.props.filterPalmsTransactions(data);
    }


    dateChangedHandler = (name, date) => {
        const d = date.format("YYYY-MM-DD");
        this.setState({
            ...this.state,
            [name]: d
        });
    }


    filterHandler = () => {
        let data = {
            ...this.state,
            page: 1,
        };

        this.props.filterPalmsTransactions(data);
    }

    clearFilters = () => {
        let data = {
            lastPage: 1,
            page: 1,
            date_from: null,
            date_to: null
        };

        this.setState({
            ...data

        });

        this.props.filterPalmsTransactions(data);


    }


    render() {
        const { classes } = this.props;

        let mappedUsers = [];

        if (this.props.palmsTransactions && this.props.palmsTransactions.data && this.props.palmsTransactions.data.length > 0) {
            mappedUsers = this.props.palmsTransactions.data.map(t => {

                const name = t.user.first_name + " " + t.user.last_name

                return {
                    tableData: [t.command, t.money, t.user.email, t.game_round_id, t.account_id, t.session_id, t.transaction_id, convertDateWithTime(t.created_at), t.description],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="warning" className={classes.card}>
                                <h4 className={classes.cardTitle}>Палм Транзакции:  {this.props.palmsTransactions.total}</h4>

                            </CardHeader>
                            <CardBody>
                                <GridContainer>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedPicker

                                            icon={<Calendar className={classes.inputIconsColor} />}
                                            labelName="От дата"
                                            onChange={(date) => this.dateChangedHandler("date_from", date)}
                                            date={this.state.date_from}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedPicker

                                            icon={<Calendar className={classes.inputIconsColor} />}
                                            labelName="До дата"
                                            onChange={(date) => this.dateChangedHandler("date_to", date)}
                                            date={this.state.date_to}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.filterHandler}
                                            color="success">
                                            Филтрирай
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Button
                                            round
                                            onClick={this.clearFilters}
                                            color="danger">
                                            Изчисти филтрите
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                                <TableWithActionButtons
                                    tableHeaderColor="gray"
                                    tableHead={["Заявка", "Сума", "Email", "Номер на стая", "Palms ID", "Сесия", "Транзакция", "Дата и час", "Описание"]}
                                    tableData={mappedUsers}

                                />
                            </CardBody>
                            <GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {this.state.lastPage > 1 ? <Pagination
                                    changePageTo={this.onChangePage}
                                    currentPage={this.state.page}
                                    totalPages={this.state.lastPage}
                                    color="warning"
                                /> : null}
                            </GridItem>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {

    return {
        palmsTransactions: state.palmsTransactions.palmsTransactions,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        filterPalmsTransactions: (data) => dispatch(filterPalmsTransactions(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PalmsTransactionsList));
