import * as actionTypes from './actionTypes'
import axios from 'utils/axios'

export const filterPalmsTransactions = (data) => {
    return dispatch => {

        axios.post('/palms/transactions', data)
            .then(res => {
                dispatch(setFilterPalmsTransactions(res.data.data));
            });
    };
};

export const setFilterPalmsTransactions = (data) => {
    return {
        type: actionTypes.FILTER_PALMS_TRANSACTIONS,
        data: data
    };
};