import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import CustomSelect from "UI/CustomSelect/CustomSelect";
import CustomPicker from "UI/CustomPicker/CustomPicker";
import Badge from "vendor/components/Badge/Badge.jsx"
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { darkBlueColor, getBadgeColorForChampionshipStatus, playoffPhases } from "utils/Constants"
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import { editChampionship } from "Pages/Championships/actions/championships.js";
import { getRoundStatuses } from "Pages/Rounds/actions/rounds"
import { Scrollbars } from 'react-custom-scrollbars';
import { warningColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import { connect } from 'react-redux';

class EditChampionship extends React.Component {

    state = {
        ...this.props.championship,
    }

    componentDidMount() {
        this.props.getRoundStatuses();
    }

    handleChangeInput = (name) => event => {

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };



    statusChangeHandler = (id) => {
        var selectedStatus = this.props.statuses.find((t) => {
            return t.id === id;
        });
        this.setState({ status: selectedStatus });
    }


    editHandler = () => {
        this.props.onClose()

        this.props.editChampionship(this.state)
    }

    render() {
        const { classes } = this.props;

        //gameStatuses
        let mappedStatuses = [];

        if (this.props.statuses && this.props.statuses.length > 0) {

            mappedStatuses = this.props.statuses.map(t => {
                return {
                    valueData: [
                        <Badge
                            key={t.id}
                            color={getBadgeColorForChampionshipStatus(t.id)}>
                            {t.name}</Badge>
                    ],
                    id: t.id
                };
            });
        }

        return (
            <div>
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="warning" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Преглед шампионат</h4>
                        </CardHeader>
                        <Scrollbars style={{ height: "60vh" }}>
                            <CardBody>
                                <GridContainer style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            labelName="Име"
                                            type="text"
                                            value={this.state.name}
                                            onChange={this.handleChangeInput("name")} />

                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomSelect
                                            disabled={this.state.status.id != 1}
                                            labelName="Статус"
                                            selectData={mappedStatuses}
                                            value={this.state.status.id}
                                            onChange={this.statusChangeHandler} />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Времетраене на полувремето"
                                            value={this.state.game_halftime_duration}
                                            onChange={this.handleChangeInput("game_halftime_duration")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Точки при победа"
                                            value={this.state.team_win_points}
                                            onChange={this.handleChangeInput("team_win_points")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Точки при равенство"
                                            value={this.state.team_draw_points}
                                            onChange={this.handleChangeInput("team_draw_points")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Точки при загуба"
                                            value={this.state.team_loss_points}
                                            onChange={this.handleChangeInput("team_loss_points")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Начален бюджет на потребител"
                                            value={this.state.user_initial_budget}
                                            onChange={this.handleChangeInput("user_initial_budget")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Максимален брой играчи в отбор на потребител"
                                            value={this.state.user_team_max_players}
                                            onChange={this.handleChangeInput("user_team_max_players")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Максимален брой играчи от един и същи клуб в отбора на потребител"
                                            value={this.state.user_team_max_players_from_same_team}
                                            onChange={this.handleChangeInput("user_team_max_players_from_same_team")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Брой вратари в отбора на потребител"
                                            value={this.state.user_team_allowed_goalkeepers}
                                            onChange={this.handleChangeInput("user_team_allowed_goalkeepers")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Брой защитници в отбора на потребител"
                                            value={this.state.user_team_allowed_defenders}
                                            onChange={this.handleChangeInput("user_team_allowed_defenders")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Брой полузащитници в отбора на потребител"
                                            value={this.state.user_team_allowed_half_defenders}
                                            onChange={this.handleChangeInput("user_team_allowed_half_defenders")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Брой нападатели в отбора на потребител"
                                            value={this.state.user_team_allowed_attackers}
                                            onChange={this.handleChangeInput("user_team_allowed_attackers")} />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Брой безплатни трансфери на кръг"
                                            value={this.state.user_team_free_transfers}
                                            onChange={this.handleChangeInput("user_team_free_transfers")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Максимален брой безплатни трансфери"
                                            value={this.state.user_team_max_free_transfers}
                                            onChange={this.handleChangeInput("user_team_max_free_transfers")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <OutlinedInput
                                            disabled={this.state.status.id != 1}
                                            fullWidth={true}
                                            type="number"
                                            labelName="Наказателни точки при извънреден трансфер"
                                            value={this.state.user_team_transfer_penalty_points}
                                            onChange={this.handleChangeInput("user_team_transfer_penalty_points")} />
                                    </GridItem>

                                </GridContainer>
                            </CardBody>
                        </Scrollbars>
                        {!(this.state.status != 1) && <CardFooter className={classes.cardFooter}>
                            <Button
                                disabled={this.state.status.id != 1}
                                onClick={this.editHandler}
                                color="warning">
                                Редактирай
                            </Button>
                        </CardFooter>}
                    </Card>
                </GridContainer>
            </div>
        );
    };

}

const styles = theme => ({
    grid: {
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitle: {
        color: darkBlueColor,
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
        zIndex: "10"
    },
    cardFooter: {
        zIndex: "10",
        background: "white",
        borderTop: "none",
        [theme.breakpoints.down("sm")]: {
            borderTop: "1px solid " + warningColor
        },
    },
    selectItem: {
        display: "flex",
        alignItems: "center",
    }
});

const mapStateToProps = state => {

    return {
        statuses: state.rounds.statuses,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        editChampionship: data => dispatch(editChampionship(data)),
        getRoundStatuses: () => dispatch(getRoundStatuses()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditChampionship));
