import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "vendor/components/Grid/GridItem.jsx";
import GridContainer from "vendor/components/Grid/GridContainer.jsx";
import Button from "vendor/components/CustomButtons/Button.jsx";
import Card from "vendor/components/Card/Card.jsx";
import CardHeader from "vendor/components/Card/CardHeader.jsx";
import CardBody from "vendor/components/Card/CardBody.jsx";
import CardFooter from "vendor/components/Card/CardFooter.jsx";
import ImageIcon from "UI/ImageIcon/ImageIcon"
import { darkBlueColor, getBadgeColorForGameStatus, playoffPhases } from "utils/Constants"
import { getUsersEmails, sendUserNotification } from "Pages/Notifications/actions/notifications"
import { connect } from 'react-redux';
import OutlinedInput from "UI/OutlinedInput/OutlinedInput";
import Message from "@material-ui/icons/Message";
import { infoColor } from "vendor/assets/jss/material-dashboard-react.jsx";
import ConfirmModal from "UI/ConfirmModal/ConfirmModal"
import AutoSuggestField from "UI/AutoSuggestField/AutoSuggestField"

class UserNotificationsForm extends React.Component {

    state = {
        body: "",
        email: "",
        confirmModalOpen: false
    }

    componentDidMount() {
        this.props.getUsersEmails();
    }

    handleChangeInput = (name) => event => {

        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    };

    confirmSendNotification = () => {

        this.setState({
            confirmModalOpen: true,
            confirmMessage: "Сигурни ли сте, че искате да пуснете Нотификация към Потребител?",
        })
    }

    closeConfirmModalHandler = (id) => {
        this.setState({ confirmModalOpen: false });
    }

    sendNotification = () => {

        this.props.sendUserNotification({ body: this.state.body, email: this.state.email });
        this.closeConfirmModalHandler()
    }


    handleChange = name => (event, { newValue }) => {
        this.setState({
            ...this.state,
            [name]: newValue,
        });
    };


    render() {
        const { classes } = this.props;
        return (
            <div>
                <ConfirmModal
                    open={this.state.confirmModalOpen}
                    close={this.closeConfirmModalHandler}
                    message={this.state.confirmMessage}
                    yesButtonClisk={this.sendNotification} />
                <GridContainer className={classes.container}>
                    <Card className={classes.card}>
                        <CardHeader color="info" className={classes.cardHeader}>
                            <h4 className={classes.cardTitle}>Нотификация към потребител</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} className={classes.grid}>
                                    <AutoSuggestField
                                        value={this.state.email}
                                        suggestions={this.props.usersEmails}
                                        label={"Еmail на потребител"}
                                        placeholder={"Потърси email на потребител"}
                                        handleChange={this.handleChange("email")}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} className={classes.grid}>
                                    <textarea
                                        className={classes.textarea}
                                        maxLength="200"
                                        type="text"
                                        id="body"
                                        name="body"
                                        placeholder="Въведете нотификация към потребител"
                                        onChange={this.handleChangeInput("body")}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button
                                onClick={this.confirmSendNotification}
                                color="info">
                                Пусни
                            </Button>
                        </CardFooter>
                    </Card>
                </GridContainer>
            </div>
        );
    };
}

const styles = {

    textarea: {
        width: "100%",
        height: "5rem",
        outline: "none",
        padding: "1rem",
        border: "2px solid " + infoColor,
        borderRadius: "5px",
        color: "black",
        fontSize: "0.85rem",
        boxSizing: "border-box"
    },

    cardTitle: {
        color: "white",
        fontWeight: "700",
        marginTop: "0px",
        minHeight: "auto",
        marginBottom: "3px",
        textDecoration: "none"
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        flexGrow: "0",
        flexBasis: "100%"
    },
    container: {
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    cardHeader: {
        width: "100%",
    },
    grid: {
        margin: "1rem"
    }

};

const mapStateToProps = state => {

    return {
        usersEmails: state.notifications.usersEmails
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getUsersEmails: () => dispatch(getUsersEmails()),
        sendUserNotification: data => dispatch(sendUserNotification(data)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserNotificationsForm));
